import React from 'react'
import Partner1 from "../../../Images/logos/partner1.png"
import Partner2 from "../../../Images/logos/partner2.png"
import Partner3 from "../../../Images/logos/partner3.png"
import Partner4 from "../../../Images/logos/partner4.png"
import Partner5 from "../../../Images/logos/partner5.png"
import Partner6 from "../../../Images/logos/partner6.png"
import Partner7 from "../../../Images/logos/partner7.png"
import Partner8 from "../../../Images/logos/partner8.png"
import Partner9 from "../../../Images/logos/partner9.png"
import Partner10 from "../../../Images/logos/partner10.png"
import Partner11 from "../../../Images/logos/partner11.png"
import Partner12 from "../../../Images/logos/partner12.png"
import { Trans, useTranslation } from 'react-i18next';


function Partner() {
    const { t } = useTranslation();
  return (
    <div class="section partners" id="partners">
    <div class="container-fluid px-lg-5 px-md-5">
        <h2 class="py-lg-3 pb-3"><Trans>{t('partners_title_1', {interpolation: {escapeValue: false}})}</Trans> <span class="text-primary"><Trans>{t('partners_title_2', {interpolation: {escapeValue: false}})}</Trans></span></h2>
        <p>
        <Trans>{t('partners_text', {interpolation: {escapeValue: false}})}</Trans>
        </p>
        <div class="row px-lg-5 px-md-5 mt-5">
            <div class="col-lg-3 col-md-6 col-sm-12">
                <div class="card py-3">
                    <img class="img-fluid" src={Partner1} alt="partner1" />
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12">
                <div class="card py-3">
                    <img class="img-fluid" src={Partner2} alt="partner1" />
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12">
                <div class="card py-3">
                    <img class="img-fluid" src={Partner3} alt="partner1" />
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12">
                <div class="card py-3">
                    <img class="img-fluid" src={Partner4} alt="partner1" />
                </div>
            </div>
        </div>    
        <div class="row px-lg-5 px-md-5 mt-lg-3">
            <div class="col-lg-3 col-md-6 col-sm-12">
                <div class="card py-3">
                    <img class="img-fluid" src={Partner5} alt="partner1" />
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12">
                <div class="card py-3">
                    <img class="img-fluid" src={Partner6} alt="partner1" />
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12">
                <div class="card py-3">
                    <img class="img-fluid" src={Partner7} alt="partner1" />
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12">
                <div class="card py-3">
                    <img class="img-fluid" src={Partner8} alt="partner1" />
                </div>
            </div>
        </div>
        <div class="row px-lg-5 px-md-5 mt-lg-3">
            <div class="col-lg-3 col-md-6 col-sm-12">
                <div class="card py-3">
                    <img class="img-fluid" src={Partner9} alt="partner1" />
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12">
                <div class="card py-3">
                    <img class="img-fluid" src={Partner10} alt="partner1" />
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12">
                <div class="card py-3">
                    <img class="img-fluid" src={Partner11} alt="partner1" />
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12">
                <div class="card py-3">
                    <img class="img-fluid" src={Partner12} alt="partner1" />
                </div>
            </div>
        </div>
    </div>
</div>
  )
}

export default Partner