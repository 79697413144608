import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RankitLogo from "../Images/Rankit-Logo.png"
import User from "../Images/user.png"
import Campaign from "../Images/Campaign.png"
import PersonPlus from "../Images/PersonPlus.png"
import Socialize from "../Images/Socialize.png"
import Review from "../Images/review.png"
import Driver1 from "../Images/driver1.png"
import Guest1 from "../Images/guest1.png"
import Picture from "../Images/Picture.png"
import LinkedIn from "../Images/LinkedIn.png"
import Add from "../Images/Add.png"
import Instagram from "../Images/Instagram.png"
import Facebook from "../Images/Facebook.png"
import Tiktok from "../Images/Tiktok.png"
import Twitter from "../Images/Twitter.png"
import Twitch from "../Images/Twitch.png"
import Line from "../Images/Line.png"
import Julian from "../Images/Julian-Opuni-MD-Fidelity-Bank-Ghana.webp" 
import Navbar from '../components/Navbar'
import { Trans, useTranslation } from 'react-i18next';
import {config_data} from "../config.js";

const Signup = () => {
  const { t } = useTranslation();
  //let { type } = useParams();
  let type='driver';
  const inputFile = useRef(null);
  // user
  const [signupType, setSignupType] = useState('driver');
  const [signupStep, setSignupStep] = useState(0);
  const [signupEmail, setSignupEmail] = useState(undefined);
  const [signupUsername, setSignupUsername] = useState(undefined);
  const [signupPassword, setSignupPassword] = useState(undefined);
  const [signupConfirmPassword, setSignupConfirmPassword] = useState(undefined);
  const [signupCountry, setSignupCountry] = useState(undefined);
  const [signupLanguage, setSignupLanguage] = useState(undefined);
  const [signupTerms, setSignupTerms] = useState(false);
  const [signupPrivacy, setSignupPrivacy] = useState(false);
  // personal info
  const [signupFirstname, setSignupFirstname] = useState(undefined);
  const [signupLastname, setSignupLastname] = useState(undefined);
  const [signupDateofbirth, setSignupDateofbirth] = useState(undefined);
  const [signupPhone, setSignupPhone] = useState(undefined);
  const [signupCity, setSignupCity] = useState(undefined);
  const [signupProfession, setSignupProfession] = useState(undefined);
  const [signupPicture, setSignupPicture] = useState(`/uploads/62bf62db288e9002d90afbaaea198a0efb982e3cfa76d15a26d2999d4baec233.png`);
	const [isFilePicked, setIsFilePicked] = useState(false);
  // social media assets
  const [signupSocialMediaAssets, setSignupSocialMediaAssets] = useState([]);
  const [signupSocialMediaAssetsUrls, setSignupSocialMediaAssetsUrls] = useState([]);
  const [signupSocialMediaAssetsLinkedinName, setSignupSocialMediaAssetsLinkedinName] = useState(undefined);
  const [signupSocialMediaAssetsLinkedinUrl, setSignupSocialMediaAssetsLinkedinUrl] = useState(undefined);
  const [signupSocialMediaAssetsInstagramName, setSignupSocialMediaAssetsInstagramName] = useState(undefined);
  const [signupSocialMediaAssetsInstagramUrl, setSignupSocialMediaAssetsInstagramUrl] = useState(undefined);
  const [signupSocialMediaAssetsFacebookName, setSignupSocialMediaAssetsFacebookName] = useState(undefined);
  const [signupSocialMediaAssetsFacebookUrl, setSignupSocialMediaAssetsFacebookUrl] = useState(undefined);
  const [signupSocialMediaAssetsTikTokName, setSignupSocialMediaAssetsTikTokName] = useState(undefined);
  const [signupSocialMediaAssetsTikTokUrl, setSignupSocialMediaAssetsTikTokUrl] = useState(undefined);
  const [signupSocialMediaAssetsTwitterName, setSignupSocialMediaAssetsTwitterName] = useState(undefined);
  const [signupSocialMediaAssetsTwitterUrl, setSignupSocialMediaAssetsTwitterUrl] = useState(undefined);
  const [signupSocialMediaAssetsTwitchName, setSignupSocialMediaAssetsTwitchName] = useState(undefined);
  const [signupSocialMediaAssetsTwitchUrl, setSignupSocialMediaAssetsTwitchUrl] = useState(undefined);

  const loadSocialMediaIcon = (val) => {
    switch(val) {
        case 0:
          return LinkedIn;
          break;
        case 1:
          return Instagram;
          break;
        case 2:
          return Facebook;
          break;
        case 3:
          return Tiktok;
          break;
        case 4:
          return Twitter;
          break;
        case 5:
          return Twitch;
          break;
        default:
          // code block
      }
  }

  const addSocialMediaAsset = (source) => {
    try {
      const filter_url_valid = /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
      console.log(`SOURCE ${source}`);
      // linkedin
      if(source==0) {
        console.log(signupSocialMediaAssetsLinkedinName);
        console.log(signupSocialMediaAssetsLinkedinUrl);
        if(signupSocialMediaAssetsLinkedinName!=undefined && signupSocialMediaAssetsLinkedinUrl!=undefined && filter_url_valid.test(signupSocialMediaAssetsLinkedinUrl) && signupSocialMediaAssetsLinkedinUrl.includes('linkedin.com')) {
          if(!signupSocialMediaAssetsUrls.includes(signupSocialMediaAssetsLinkedinUrl)) {
            let obj = {social_media: 'Linkedin', name: signupSocialMediaAssetsLinkedinName, url: signupSocialMediaAssetsLinkedinUrl};
            signupSocialMediaAssets.push(obj);
            signupSocialMediaAssetsUrls.push(signupSocialMediaAssetsLinkedinUrl);
            setSignupSocialMediaAssetsLinkedinName('');
            setSignupSocialMediaAssetsLinkedinUrl('');
            toast.success(t('signup_errors_social_media_assets_success'));
          } else {
            toast.error(t('signup_errors_social_media_assets_already'));
          }
        } else {
          //toast(t('signup_errors_email'));
          toast.error(t('signup_errors_social_media_assets_linkedin'));
        }
      }
      // instagram
      if(source==1) {
        if(signupSocialMediaAssetsInstagramName!=undefined && signupSocialMediaAssetsInstagramUrl!=undefined && filter_url_valid.test(signupSocialMediaAssetsInstagramUrl) && signupSocialMediaAssetsInstagramUrl.includes('instagram.com')) {
          if(!signupSocialMediaAssetsUrls.includes(signupSocialMediaAssetsInstagramUrl)) {
            let obj = {social_media: 'Instagram', name: signupSocialMediaAssetsInstagramName, url: signupSocialMediaAssetsInstagramUrl};
            signupSocialMediaAssets.push(obj);
            signupSocialMediaAssetsUrls.push(signupSocialMediaAssetsInstagramUrl);
            setSignupSocialMediaAssetsInstagramName('');
            setSignupSocialMediaAssetsInstagramUrl('');
            toast.success(t('signup_errors_social_media_assets_success'));
          } else {
            toast.error(t('signup_errors_social_media_assets_already'));
          }
        } else {
          //toast(t('signup_errors_email'));
          toast.error(t('signup_errors_social_media_assets_instagram'));
        }
      }
        // facebook
        if(source==2) {
          if(signupSocialMediaAssetsFacebookName!=undefined && signupSocialMediaAssetsFacebookUrl!=undefined && filter_url_valid.test(signupSocialMediaAssetsFacebookUrl) && signupSocialMediaAssetsFacebookUrl.includes('facebook.com')) {
            if(!signupSocialMediaAssetsUrls.includes(signupSocialMediaAssetsFacebookUrl)) {
              let obj = {social_media: 'Facebook', name: signupSocialMediaAssetsFacebookName, url: signupSocialMediaAssetsFacebookUrl};
              signupSocialMediaAssets.push(obj);
              signupSocialMediaAssetsUrls.push(signupSocialMediaAssetsFacebookUrl);
              setSignupSocialMediaAssetsFacebookName('');
              setSignupSocialMediaAssetsFacebookUrl('');
              toast.success(t('signup_errors_social_media_assets_success'));
            } else {
              toast.error(t('signup_errors_social_media_assets_already'));
            }
          } else {
            //toast(t('signup_errors_email'));
            toast.error(t('signup_errors_social_media_assets_facebook'));
          }
        }
          // tik tok
          if(source==3) {
            if(signupSocialMediaAssetsTikTokName!=undefined && signupSocialMediaAssetsTikTokUrl!=undefined && filter_url_valid.test(signupSocialMediaAssetsTikTokUrl) && signupSocialMediaAssetsTikTokUrl.includes('tiktok.com')) {
              if(!signupSocialMediaAssetsUrls.includes(signupSocialMediaAssetsTikTokUrl)) {
                let obj = {social_media: 'TikTok', name: signupSocialMediaAssetsTikTokName, url: signupSocialMediaAssetsTikTokUrl};
                signupSocialMediaAssets.push(obj);
                signupSocialMediaAssetsUrls.push(signupSocialMediaAssetsTikTokUrl);
                setSignupSocialMediaAssetsTikTokName(undefined);
                setSignupSocialMediaAssetsTikTokUrl(undefined);
                toast.success(t('signup_errors_social_media_assets_success'));
              } else {
                toast.error(t('signup_errors_social_media_assets_already'));
              }
            } else {
              //toast(t('signup_errors_email'));
              toast.error(t('signup_errors_social_media_assets_tiktok'));
            }
          }
            // twitter
          if(source==4) {
              if(signupSocialMediaAssetsTwitterName!=undefined && signupSocialMediaAssetsTwitterUrl!=undefined && filter_url_valid.test(signupSocialMediaAssetsTwitterUrl) && signupSocialMediaAssetsTwitterUrl.includes('twitter.com')) {
                if(!signupSocialMediaAssetsUrls.includes(signupSocialMediaAssetsTwitterUrl)) {
                  let obj = {social_media: 'Twitter', name: signupSocialMediaAssetsTwitterName, url: signupSocialMediaAssetsTwitterUrl};
                  signupSocialMediaAssets.push(obj);
                  signupSocialMediaAssetsUrls.push(signupSocialMediaAssetsTwitterUrl);
                  setSignupSocialMediaAssetsTwitterName('');
                  setSignupSocialMediaAssetsTwitterUrl('');
                  toast.success(t('signup_errors_social_media_assets_success'));
                } else {
                  toast.error(t('signup_errors_social_media_assets_already'));
                }
              } else {
                //toast(t('signup_errors_email'));
                toast.error(t('signup_errors_social_media_assets_twitter'));
              }
          }
          // twitch
          if(source==5) {
            if(signupSocialMediaAssetsTwitchName!=undefined && signupSocialMediaAssetsTwitchUrl!=undefined && filter_url_valid.test(signupSocialMediaAssetsTwitchUrl) && signupSocialMediaAssetsTwitchUrl.includes('twitch.com')) {
              if(!signupSocialMediaAssetsUrls.includes(signupSocialMediaAssetsTwitchUrl)) {
                let obj = {social_media: 'Twitch', name: signupSocialMediaAssetsTwitchName, url: signupSocialMediaAssetsTwitchUrl};
                signupSocialMediaAssets.push(obj);
                signupSocialMediaAssetsUrls.push(signupSocialMediaAssetsTwitchUrl);
                setSignupSocialMediaAssetsTwitchName('');
                setSignupSocialMediaAssetsTwitchUrl('');
                toast.success(t('signup_errors_social_media_assets_success'));
              } else {
                toast.error(t('signup_errors_social_media_assets_already'));
              }
            } else {
              //toast(t('signup_errors_email'));
              toast.error(t('signup_errors_social_media_assets_twitch'));
            }
          }
    } catch(err) {
      toast.error(err.toString());
    }
  }
  
  const onButtonClick = () => {
    // `current` points to the mounted file input element
   inputFile.current.click();
  };

  const changeHandler = async (event) => {
    console.log('HEREEEEE');
		setIsFilePicked(true);
    console.log(event.target.files[0]);
    var formData = new FormData();
    //var imagefile = document.querySelector('#file');
    formData.append('file', event.target.files[0]);
    const response = await axios.post(`${config_data['node_url']}/upload`, formData);
    //console.log(response['data']['data']);
    setSignupPicture(response['data']['data']);
	};

  // helpers //

  function upperCase(input) {
    return input.toUpperCase();
  }
  
  function lowerCase(input) {
    return input.toLowerCase();
  }

  function capitalizeWords(input) {
    const words = input.toLowerCase().split(' ');
    const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
    const result = capitalizedWords.join(' ');
    return result;
  }
  
  function capitalizeSingleWord(input) {
    const pre = input.toLowerCase()
    return pre.charAt(0).toUpperCase() + pre.slice(1);
  }
  
  
  const isValidPhoneNumber = (input) => {
      const phoneNumberRegex = /^\+\d{2,}-\d+$/;
      return phoneNumberRegex.test(input);
  };


  const manage = async (step) => {
    try {
      console.log(`EMAIL ${signupEmail}`);
      console.log(`TERMS ${signupTerms}`);
      console.log(`PRIVACY ${signupPrivacy}`);
      console.log(`STEP ${signupStep}`);
      var filter = /^([a-zA-Z0-9_+\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/; 
      var filter_strength = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/;



      if(signupStep==0 &&  (signupEmail==undefined || !filter.test(signupEmail))) {
        toast.error(t('signup_errors_email'));
      } else if(signupStep==0 && signupUsername==undefined) {
        toast.error(t('signup_errors_username'));
      } else if(signupStep==0 && (signupUsername!=undefined && signupUsername.length<5)) {
        toast.error(t('signup_errors_username_length'));
      } else if(signupStep==0 && signupPassword==undefined) {
        toast.error(t('signup_errors_password'));
      } else if(!filter_strength.test(signupPassword)) {
        toast.error(t('signup_errors_password_strength'));
      } else if(signupStep==0 && signupConfirmPassword==undefined) {
        toast.error(t('signup_errors_confirm_password'));
      } else if(signupStep==0 && signupPassword!=signupConfirmPassword) {
        toast.error(t('signup_errors_wrong_passwords'));
      } else if(signupStep==0 && (signupCountry==undefined || signupCountry==0)) {
        toast.error(t('signup_errors_country'));
      } else if(signupStep==0 && (signupLanguage==undefined || signupLanguage==0)) {
        toast.error(t('signup_errors_language'));
      } else if(signupStep==0 && signupTerms==false) {
        toast.error(t('signup_errors_terms'));
      } else if(signupStep==0 && signupPrivacy==false) {
        toast.error(t('signup_errors_privacy'));
      } else if(signupStep==1 && signupFirstname==undefined) {
        toast.error(t('signup_errors_firstname'));
      } else if(signupStep==1 && signupLastname==undefined) {
        toast.error(t('signup_errors_lastname'));
      } else if(signupStep==1 && signupDateofbirth==undefined) {
        toast.error(t('signup_errors_dateofbirth'));
      } else if(signupStep==1 && ((moment().unix() - moment(signupDateofbirth).unix()) < 567648000)) {
        toast.error(t('signup_errors_dateofbirth'));
      } else if(signupStep==1 && (signupPhone==undefined || !isValidPhoneNumber(signupPhone))) {
        toast.error(t('signup_errors_phone'));
      } else if(signupStep==1 && signupCity==undefined) {
        toast.error(t('signup_errors_city'));  
      } else if(signupStep==1 && signupProfession==undefined) {
        toast.error(t('signup_errors_profession'));  
      } else if(signupStep==1 && (signupPicture==undefined || signupPicture!=undefined && isFilePicked==false)) {
        toast.error(t('signup_errors_picture'));
      } else if(signupStep==2 && signupSocialMediaAssets.length==0) {
        toast.error(t('signup_errors_social_media_assets_count')); 
      } else {
        console.log(moment().unix());
        console.log(moment(signupDateofbirth).unix());
        if(signupStep==0) {
          console.log(`CHECK USERNAME AND EMAIL`);
          var response = await axios.post(`${config_data['node_url']}/check_email`, { email: signupEmail });
          console.log(response)
          if(response['data']['data']=='0') {
            toast.error(t('signup_errors_email_already'));
            return false;
          }
          response = await axios.post(`${config_data['node_url']}/check_username`, { username: signupUsername });
          if(response['data']['data']=='0') {
            toast.error(t('signup_errors_username_already'));
            return false;
          }
          
        }
        //return false;
        if(step<3) {
          // ADVANCE
          var tabs = document.querySelectorAll('[att="navatt"]');
          var tabscontent = document.querySelectorAll('[role="tabpanel"]');
          console.log(tabscontent);
          for(var i=0; i<tabs.length; i++) {
            console.log(tabs[i]);
            //console.log(tabs[i].type);
            tabs[i].setAttribute("class", "nav-link");
            tabs[i].setAttribute("aria-selected", "false");
          }
          for(var i=0; i<tabscontent.length; i++) {
            tabscontent[i].setAttribute("class", "tab-pane fade");
          }
          tabs[step+1].setAttribute("class", "nav-link active");
          tabs[step+1].setAttribute("aria-selected", "true");
          tabscontent[step+1].setAttribute("class", "tab-pane fade show active");
          setSignupStep(step+1);
          if(step==2) {
            for(var i=0; i<tabs.length; i++) {
              tabs[i].setAttribute("data-bs-toggle", "pill");
            }
          }
        } else {
          const response = await axios.post(`${config_data['node_url']}/signup`, {email: lowerCase(signupEmail), username: signupUsername, password: signupPassword, country: signupCountry, language: signupLanguage, user_type: "driver", social_media_assets: signupSocialMediaAssets, firstname: capitalizeSingleWord(signupFirstname), lastname: capitalizeSingleWord(signupLastname), birth_date: moment(signupDateofbirth).format('DD/MM/YYYY'), phone: signupPhone, city: capitalizeSingleWord(signupCity), profession: capitalizeSingleWord(signupProfession), image: signupPicture, user_type: signupType});
          if(response['data']['result']!='ok') {
            toast.error(response['data']['data']);
            return false;
          } else {
            toast.success(t('signup_ok'), {onClose: () => window.location.reload()});
          }  
        }
      }
    } catch(err) {
      toast.error(err.toString());
    }
  }

  return (
    
    <div class="dark">
    
    <Navbar/>
    {type && (type=='advertiser' || type=='driver' || type=='admin') && (
      <section class="signUp my-5 py-5">
        <div class="container my-3">
          <div class="row align-items-center ">
            <ul
              class="nav nav-pills row justify-content-center mb-3 px-3 px-lg-0"
              id="pills-tab"
              role="tablist"
            >
              {/*<li class="nav-item col-lg-2" role="presentation">
                <button
                  class="nav-link active"
                  id="pills-path-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-path"
                  type="button"
                  role="tab"
                  aria-controls="pills-path"
                  aria-selected="true"
                >
                  <img
                    src={Campaign}
                    alt=""
                    class="img-fluid mx-2"
                  />{" "}
                  <Trans>{t('signup_select_path_title', {interpolation: {escapeValue: false}})}</Trans>
                </button>
              </li>*/}


{/*data-bs-toggle="pill"*/}
              <li class="nav-item col-lg-2" role="presentation">
                <button
                  class="nav-link active"
                  id="pills-user-tab"
                  
                  data-bs-target="#pills-user"
                  att="navatt"
                  type="button"
                  role="tab"
                  aria-controls="pills-user"
                  aria-selected="true"
                >
                  <img
                    src={User}
                    alt=""
                    class="img-fluid mx-2"
                  />{" "}
                  <Trans>{t('signup_user_title', {interpolation: {escapeValue: false}})}</Trans>
                </button>
              </li>

              <li class="nav-item col-lg-2" role="presentation">
                <button
                  class="nav-link"
                  id="pills-Personal-tab"
          
                  att="navatt"
                  data-bs-target="#pills-Personal"
                  type="button"
                  role="tab"
                  aria-controls="pills-Personal"
                  aria-selected="false"
                  onClick={(e) => { e.stopPropagation();}}
                >
                  <img
                    src={PersonPlus}
                    alt=""
                    class="img-fluid mx-2"
                  />{" "}
                 <Trans>{t('signup_personal_info_title', {interpolation: {escapeValue: false}})}</Trans>
                </button>
              </li>

              <li class="nav-item col-lg-2" role="presentation">
                <button
                  class="nav-link"
                  id="pills-Social-tab"
            
                  data-bs-target="#pills-Social"
                  att="navatt"
                  type="button"
                  role="tab"
                  aria-controls="pills-Social"
                  aria-selected="false"
                  //event.preventDefault()
                >
                  <img
                    src={Socialize}
                    alt=""
                    class="img-fluid mx-2"
                  />{" "}
                  <Trans>{t('signup_social_networks_title', {interpolation: {escapeValue: false}})}</Trans>
                </button>
              </li>

              <li class="nav-item col-lg-3" role="presentation">
                <button
                  class="nav-link"
                  id="pills-Review-tab"
      
                  data-bs-target="#pills-Review"
                  att="navatt"
                  type="button"
                  role="tab"
                  aria-controls="pills-Review"
                  aria-selected="false"
                >
                  <img
                    src={Review}
                    alt=""
                    class="img-fluid mx-2"
                  />{" "}
                  <Trans>{t('signup_review_title', {interpolation: {escapeValue: false}})}</Trans>
                </button>
              </li>
            </ul>
            <div class="tab-content" id="pills-tabContent">
              {/* Path Selection */}
            {/*}  <div
                class="tab-pane fade show active"
                id="pills-path"
                role="tabpanel"
                aria-labelledby="pills-path-tab"
              >
                <div class="container">
                  <div class="row align-items-center my-5 g-5">
                    <div class="col-lg-6 Driver px-4">
                      <img
                        src={Driver1}
                        alt=""
                        class="img-fluid my-4"
                      />

                      <h4 class="my-3">
                      <Trans>{t('signup_select_path_become_driver', {interpolation: {escapeValue: false}})}</Trans>
                      </h4>

                      <a
                        href="#"
                        class="btn btn-white my-5"
                      >
                        <Trans>{t('join_our_family_driver_button', {interpolation: {escapeValue: false}})}</Trans>
                      </a>
                    </div>

                    <div class="col-lg-6 Advertiser px-4 text-end">
                      <img
                        src={Guest1}
                        alt=""
                        class="img-fluid my-4"
                      />

                      <h4 class="my-3">
                      <Trans>{t('signup_select_path_become_advertiser', {interpolation: {escapeValue: false}})}</Trans>
                      </h4>

                      <a
                        href="#"
                        class="btn btn-white my-5"
                      >
                        <Trans>{t('join_our_family_advertiser_button', {interpolation: {escapeValue: false}})}</Trans>
                      </a>
                    </div>
                  </div>
                </div>
</div> */}

              {/* Path Selection */}

              {/* User */}

              <div
                class="tab-pane fade show active"
                id="pills-user"
                role="tabpanel"
                aria-labelledby="pills-user-tab"
              >
                <div class="container my-4 ">
                  <div class="row">
                    <div class="col-lg-6 Forms">
                      <div class="form-floating my-4 ">
                        <input
                          type="email"
                          class="form-control"
                          id="floatingInput"
                          placeholder="name@example.com"
                          onChange={e => setSignupEmail(e.target.value)}
                        />
                        <label for="floatingInput"><Trans>{t('signup_user_email', {interpolation: {escapeValue: false}})}</Trans></label>
                      </div>

                      <div class="form-floating my-4">
                        <input
                          type="text"
                          class="form-control"
                          id="floatingPassword"
                          placeholder="Password"
                          onChange={e => setSignupUsername(e.target.value)}
                        />
                        <label for="floatingPassword"><Trans>{t('signup_user_username', {interpolation: {escapeValue: false}})}</Trans></label>
                      </div>

                      <div class="form-floating my-4">
                        <input
                          type="password"
                          class="form-control"
                          id="floatingPassword"
                          placeholder="Password"
                          onChange={e => setSignupPassword(e.target.value)}
                        />
                        <label for="floatingPassword"><Trans>{t('signup_user_password', {interpolation: {escapeValue: false}})}</Trans></label>
                      </div>

                      <div class="form-floating my-4">
                        <input
                          type="password"
                          class="form-control"
                          id="floatingPassword"
                          placeholder="Password"
                          onChange={e => setSignupConfirmPassword(e.target.value)}
                        />
                        <label for="floatingPassword"><Trans>{t('signup_user_confirm_password', {interpolation: {escapeValue: false}})}</Trans></label>
                      </div>
                    </div>

                    <div class="col-lg-6 Forms px-lg-4">
                      <div class="form-floating my-lg-4 ">
                        {/*<input
                          type="email"
                          class="form-control"
                          id="floatingInput"
                          placeholder="name@example.com"
                          onChange={e => setSignupCountry(e.target.value)}
                        />*/}
                        <select class="form-control" style={{'color':'#b4b7bd', 'backgroundColor': "#161D31", 'borderColor': '#404656'}} onChange={e => setSignupCountry(e.target.value)}> 
                          <option value="0">{t('signup_user_country_select', {interpolation: {escapeValue: false}})}</option>
                        {config_data['countries'].map((c) => (
                          <option value={c['name']}>{c['name']}</option>
                          ))}
                        </select>
                        <label for="floatingInput"><Trans>{t('signup_user_country', {interpolation: {escapeValue: false}})}</Trans></label>
                      </div>

                      <div class="form-floating my-4">
                        {/*<input
                          type="text"
                          class="form-control"
                          id="floatingPassword"
                          placeholder="Password"
                          onChange={e => setSignupLanguage(e.target.value)}
                        />*/}
                        <select placeholder="Password" class="form-control" style={{'color':'#b4b7bd', 'backgroundColor': "#161D31", 'borderColor': '#404656'}} onChange={e => setSignupLanguage(e.target.value)}>
                        <option value="0">{t('signup_user_language_select', {interpolation: {escapeValue: false}})}</option>
                        {config_data['languages'].map((c) => (
                          <option value={c['name']}>{c['name']}</option>
                          ))}
                        </select>
                        <label for="floatingPassword"><Trans>{t('signup_user_language', {interpolation: {escapeValue: false}})}</Trans></label>
                      </div>

                      <div class="form-check my-3">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                          onClick={e => setSignupTerms(!signupTerms)}
                        />
                        <label class="form-check-label" for="flexCheckDefault">
                        <Trans><a href={t('signup_user_terms_url')} target="_blank">{t('signup_user_terms', {interpolation: {escapeValue: false}})}</a></Trans>
                        </label>
                      </div>

                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                          onClick={e => setSignupPrivacy(!signupPrivacy)}
                        />
                        <label class="form-check-label" for="flexCheckDefault">
                        <Trans><a href={t('signup_user_privacy_url')} target="_blank">{t('signup_user_privacy', {interpolation: {escapeValue: false}})}</a></Trans>
                        </label>
                      </div>

                      <div class="text-end">
                        <button class="btn my-4 " onClick={() => {manage(0)}}><Trans>{t('signup_user_button', {interpolation: {escapeValue: false}})}</Trans></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* User */}

              {/* Info */}
              <div
                class="tab-pane fade"
                id="pills-Personal"
                role="tabpanel"
                aria-labelledby="pills-Personal-tab"
              >
                <div class="container my-4 ">
                  <div class="row">
                    <div class="col-lg-6 Forms">
                    <div class="form-floating my-4">
                        <div
                          class="image"
                          style={{backgroundImage: `url(${config_data['node_url']}${signupPicture})`}}
                        >
                          <img
                            src={Picture}
                            alt=""
                            class="img-fluid Select"
                            onClick={onButtonClick}
                          />
                        </div>
                        <input type='file' id='file' ref={inputFile} onChange={changeHandler} style={{display: 'none'}}/> 
                      
                      </div>

                      <div class="form-floating my-4">                        
                      <input
                          type="text"
                          class="form-control"
                          id="floatingPassword"
                          placeholder="Password"
                          onChange={e => setSignupFirstname(e.target.value)}
                        />
                        <label for="floatingPassword"><Trans>{t('signup_personal_info_first_name', {interpolation: {escapeValue: false}})}</Trans></label>
                      </div>

                      <div class="form-floating my-4">
                        <input
                          type="text"
                          class="form-control"
                          id="floatingPassword"
                          placeholder="Password"
                          onChange={e => setSignupLastname(e.target.value)}
                        />
                        <label for="floatingPassword"><Trans>{t('signup_personal_info_last_name', {interpolation: {escapeValue: false}})}</Trans></label>
                      </div>

                      
                    </div>

                    <div class="col-lg-6 Forms px-lg-4">
                    {/*  <div class="form-floating my-lg-4 ">
                        <input
                          type="email"
                          class="form-control"
                          id="floatingInput"
                          placeholder="name@example.com"
                        />
                        <label for="floatingInput"><Trans>{t('signup_personal_info_country', {interpolation: {escapeValue: false}})}</Trans></label>
                        </div> */}

                      <div class="form-floating my-4">
                        <input
                          type="date"
                          class="form-control"
                          id="floatingPassword"
                          onChange={e => setSignupDateofbirth(e.target.value)}
                        />
                        <label for="floatingPassword"><Trans>{t('signup_personal_info_date_of_birth', {interpolation: {escapeValue: false}})}</Trans></label>
                      </div>

                      <div class="form-floating my-4">
                        <input
                          type="text"
                          class="form-control"
                          id="floatingPassword"
                          placeholder="Password"
                          onChange={e => setSignupPhone(e.target.value)}
                        />
                        <label for="floatingPassword"><Trans>{t('signup_personal_info_phone', {interpolation: {escapeValue: false}})}</Trans></label>
                      </div>

                      <div class="form-floating my-4">
                        <input
                          type="text"
                          class="form-control"
                          id="floatingPassword"
                          placeholder="Password"
                          onChange={e => setSignupCity(e.target.value)}
                        />
                        <label for="floatingPassword"><Trans>{t('signup_personal_info_city', {interpolation: {escapeValue: false}})}</Trans></label>
                      </div>

                      <div class="form-floating my-4">
                        <input
                          type="text"
                          class="form-control"
                          id="floatingPassword"
                          placeholder="Password"
                          onChange={e => setSignupProfession(e.target.value)}
                        />
                        <label for="floatingPassword"><Trans>{t('signup_personal_info_profession', {interpolation: {escapeValue: false}})}</Trans></label>
                      </div>

                      <div class="text-end">
                        <button class="btn my-4 " onClick={() => {manage(1)}}><Trans>{t('signup_personal_info_button', {interpolation: {escapeValue: false}})}</Trans></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Info */}

              {/* Social */}
              <div
                class="tab-pane fade"
                id="pills-Social"
                role="tabpanel"
                aria-labelledby="pills-Social-tab"
              >
                <div class="container my-4 ">
                  <div class="row d-flex justify-content-center">
                    <div class="col-lg-6 px-4">

                      {/* LinkedIn */}

                      <div class="socialLink my-5">
                        {/* Social Logo */}
                        <img
                          src={LinkedIn}
                          alt=""
                          class="img-fluid"
                        />&nbsp;Linkedin

                        {/* Form Feilds */}

                        <div class="Forms ">
                          {/* Page Name */}
                          <div class="form-floating my-4">
                            <input
                              type="text"
                              class="form-control"
                              id="floatingPassword"
                              placeholder="Password"
                              onChange={e => setSignupSocialMediaAssetsLinkedinName(e.target.value)}
                              value={signupSocialMediaAssetsLinkedinName}
                            />
                            <label for="floatingPassword">
                            <Trans>{t('signup_social_profile_name', {interpolation: {escapeValue: false}})}</Trans>
                            </label>
                          </div>

                          {/* Page URL */}
                          <div class="form-floating my-4">
                            <input
                              type="text"
                              class="form-control"
                              id="floatingPassword"
                              placeholder="Password"
                              onChange={e => setSignupSocialMediaAssetsLinkedinUrl(e.target.value)}
                              value={signupSocialMediaAssetsLinkedinUrl}
                            />
                            <label for="floatingPassword">
                            <Trans>{t('signup_social_profile_url', {interpolation: {escapeValue: false}})}</Trans>
                            </label>
                          </div>

                          {/* Add Button */}

                          <button class="addBtn" onClick={e => addSocialMediaAsset(0)}>
                            <img
                              src={Add}
                              alt=""
                              class="img-fluid"
                            />
                            <Trans>{t('signup_social_profile_button', {interpolation: {escapeValue: false}})}</Trans>
                          </button>
                        </div>
                      </div>

                      {/* Instagram */}

                      <div class="socialLink my-5">
                        <img
                          src={Instagram}
                          alt=""
                          class="img-fluid"
                        />
                        &nbsp;Instagram

                        {/* Form Feilds */}

                        <div class="Forms ">
                          {/* Page Name */}
                          <div class="form-floating my-4">
                            <input
                              type="text"
                              class="form-control"
                              id="floatingPassword"
                              placeholder="Password"
                              onChange={e => setSignupSocialMediaAssetsInstagramName(e.target.value)}
                              value={signupSocialMediaAssetsInstagramName}
                            />
                            <label for="floatingPassword">
                              <Trans>{t('signup_social_profile_name', {interpolation: {escapeValue: false}})}</Trans>
                            </label>
                          </div>

                          <div class="form-floating my-4">
                            <input
                              type="text"
                              class="form-control"
                              id="floatingPassword"
                              placeholder="Password"
                              onChange={e => setSignupSocialMediaAssetsInstagramUrl(e.target.value)}
	                            value={signupSocialMediaAssetsInstagramUrl}
                            />
                            <label for="floatingPassword">
                            <Trans>{t('signup_social_profile_url', {interpolation: {escapeValue: false}})}</Trans>
                            </label>
                          </div>

                          <button class="addBtn" onClick={e => addSocialMediaAsset(1)}>
                            <img
                              src={Add}
                              alt=""
                              class="img-fluid "
                            />
                            <Trans>{t('signup_social_profile_button', {interpolation: {escapeValue: false}})}</Trans>
                          </button>
                        </div>
                      </div>

                      {/* Facebook */}

                      <div class="socialLink my-5">
                        <img
                          src={Facebook}
                          alt=""
                          class="img-fluid"
                        />&nbsp;Facebook

                        <div class="Forms ">
                          <div class="form-floating my-4">
                            <input
                              type="text"
                              class="form-control"
                              id="floatingPassword"
                              placeholder="Password"
                              onChange={e => setSignupSocialMediaAssetsFacebookName(e.target.value)}
                              value={signupSocialMediaAssetsFacebookName}
                            />
                            <label for="floatingPassword">
                            <Trans>{t('signup_social_profile_name', {interpolation: {escapeValue: false}})}</Trans>
                            </label>
                          </div>

                          <div class="form-floating my-4">
                            <input
                              type="text"
                              class="form-control"
                              id="floatingPassword"
                              placeholder="Password"
                              onChange={e => setSignupSocialMediaAssetsFacebookUrl(e.target.value)}
	                            value={signupSocialMediaAssetsFacebookUrl}
                            />
                            <label for="floatingPassword">
                            <Trans>{t('signup_social_profile_url', {interpolation: {escapeValue: false}})}</Trans>
                            </label>
                          </div>

                          <button class="addBtn" onClick={e => addSocialMediaAsset(2)}>
                            <img
                              src={Add}
                              alt=""
                              class="img-fluid "
                            />
                            <Trans>{t('signup_social_profile_button', {interpolation: {escapeValue: false}})}</Trans>
                          </button>
                        </div>
                      </div>

                       {/* TikTok*/}
                      <div class="socialLink my-5">
                        <img
                          src={Tiktok}
                          alt=""
                          class="img-fluid"
                        />&nbsp;TikTok

                        <div class="Forms ">
                          <div class="form-floating my-4">
                            <input
                              type="text"
                              class="form-control"
                              id="floatingPassword"
                              placeholder="Password"
                              onChange={e => setSignupSocialMediaAssetsTikTokName(e.target.value)}
                              value={signupSocialMediaAssetsTikTokName}
                            />
                            <label for="floatingPassword">
                            <Trans>{t('signup_social_profile_name', {interpolation: {escapeValue: false}})}</Trans>
                            </label>
                          </div>

                          <div class="form-floating my-4">
                            <input
                              type="text"
                              class="form-control"
                              id="floatingPassword"
                              placeholder="Password"
                              onChange={e => setSignupSocialMediaAssetsTikTokUrl(e.target.value)}
	                            value={signupSocialMediaAssetsTikTokUrl}
                            />
                            <label for="floatingPassword">
                            <Trans>{t('signup_social_profile_url', {interpolation: {escapeValue: false}})}</Trans>
                            </label>
                          </div>

                          <button class="addBtn" onClick={e => addSocialMediaAsset(3)}>
                            <img
                              src={Add}
                              alt=""
                              class="img-fluid "
                            />
                            <Trans>{t('signup_social_profile_button', {interpolation: {escapeValue: false}})}</Trans>
                          </button>
                        </div>
                      </div>

                      {/* Twitter */}
                      <div class="socialLink my-5">
                        <img
                          src={Twitter}
                          alt=""
                          class="img-fluid"
                        />&nbsp;Twitter

                        <div class="Forms ">
                          <div class="form-floating my-4">
                            <input
                              type="text"
                              class="form-control"
                              id="floatingPassword"
                              placeholder="Password"
                              onChange={e => setSignupSocialMediaAssetsTwitterName(e.target.value)}
                              value={signupSocialMediaAssetsTwitterName}
                            />
                            <label for="floatingPassword">
                            <Trans>{t('signup_social_profile_name', {interpolation: {escapeValue: false}})}</Trans>
                            </label>
                          </div>

                          <div class="form-floating my-4">
                            <input
                              type="text"
                              class="form-control"
                              id="floatingPassword"
                              placeholder="Password"
                              onChange={e => setSignupSocialMediaAssetsTwitterUrl(e.target.value)}
	                            value={signupSocialMediaAssetsTwitterUrl}
                            />
                            <label for="floatingPassword">
                            <Trans>{t('signup_social_profile_url', {interpolation: {escapeValue: false}})}</Trans>
                            </label>
                          </div>

                          <button class="addBtn" onClick={e => addSocialMediaAsset(4)}>
                            <img
                              src={Add}
                              alt=""
                              class="img-fluid "
                            />
                            <Trans>{t('signup_social_profile_button', {interpolation: {escapeValue: false}})}</Trans>
                          </button>
                        </div>
                      </div>

                      {/* Twitch */}
                      <div class="socialLink my-5">
                        <img
                          src={Twitch}
                          alt=""
                          class="img-fluid"
                        />&nbsp;Twitch

                        <div class="Forms ">
                          <div class="form-floating my-4">
                            <input
                              type="text"
                              class="form-control"
                              id="floatingPassword"
                              placeholder="Password"
                              onChange={e => setSignupSocialMediaAssetsTwitchName(e.target.value)}
                              value={signupSocialMediaAssetsTwitchName}
                            />
                            <label for="floatingPassword">
                            <Trans>{t('signup_social_profile_name', {interpolation: {escapeValue: false}})}</Trans>
                            </label>
                          </div>

                          <div class="form-floating my-4">
                            <input
                              type="text"
                              class="form-control"
                              id="floatingPassword"
                              placeholder="Password"
                              onChange={e => setSignupSocialMediaAssetsTwitchUrl(e.target.value)}
	                            value={signupSocialMediaAssetsTwitchUrl}
                            />
                            <label for="floatingPassword">
                            <Trans>{t('signup_social_profile_url', {interpolation: {escapeValue: false}})}</Trans>
                            </label>
                          </div>

                          <button class="addBtn" onClick={e => addSocialMediaAsset(5)}>
                            <img
                              src={Add}
                              alt=""
                              class="img-fluid "
                            />
                            <Trans>{t('signup_social_profile_button', {interpolation: {escapeValue: false}})}</Trans>
                          </button>
                        </div>
                      </div>

                      <div class="text-end">
                        <a href="#" class="btn" onClick={() => {manage(2)}}>
                          {" "}
                          <Trans>{t('signup_social_button', {interpolation: {escapeValue: false}})}</Trans>{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="tab-pane fade"
                id="pills-Review"
                role="tabpanel"
                aria-labelledby="pills-Review-tab"
              >
                <div class="container">
                  <div class="row  justify-content-center">
                    <div class="col-lg-5 reviewContainer my-4 p-4">
                      <div class="text-center my-5">
                        <div class=" my-4 d-flex justify-content-center text-center">
                          <div
                            class="picturecontainer "
                            style={{backgroundImage: `url(${config_data['node_url']}${signupPicture})`}}
                          ></div>
                        </div>

                        <p class="name">{signupFirstname} {signupLastname}</p>
                      </div>

                      <div class="miniTitle my-5">
                        {/*<h4 class="my-2"> Details</h4>*/}

                        <img
                          src={Line}
                          alt=""
                          class="img-fluid"
                        />

                        <p class="Bio my-4">
                          {" "}
                          <a href="" class="BioTitle">
                            {" "}
                            <Trans>{t('signup_review_username', {interpolation: {escapeValue: false}})}</Trans>:{" "}
                          </a>{" "}
                          {signupUsername}{" "}
                        </p>
                        <p class="Bio my-4">
                          {" "}
                          <a href="" class="BioTitle">
                            {" "}
                            <Trans>{t('signup_review_email', {interpolation: {escapeValue: false}})}</Trans>:{" "}
                          </a>{" "}
                          {signupEmail}{" "}
                        </p>
                        <p class="Bio my-4">
                          {" "}
                          <a href="" class="BioTitle">
                            {" "}
                            <Trans>{t('signup_review_phone', {interpolation: {escapeValue: false}})}</Trans>:{" "}
                          </a>{" "}
                          {signupPhone}{" "}
                        </p>
                        <p class="Bio my-4">
                          {" "}
                          <a href="" class="BioTitle">
                            {" "}
                            <Trans>{t('signup_review_dob', {interpolation: {escapeValue: false}})}</Trans>:{" "}
                          </a>{" "}
                          {moment(signupDateofbirth).format('DD/MM/YYYY')}
                        </p>
                        <p class="Bio my-4">
                          {" "}
                          <a href="" class="BioTitle">
                            {" "}
                            <Trans>{t('signup_review_language', {interpolation: {escapeValue: false}})}</Trans>:{" "}
                          </a>{" "}
                          {signupLanguage}{" "}
                        </p>
                        <p class="Bio mt-4">
                          {" "}
                          <a href="" class="BioTitle">
                            {" "}
                            <Trans>{t('signup_review_city', {interpolation: {escapeValue: false}})}</Trans>:{" "}
                          </a>{" "}
                          {signupCity}{" "}
                        </p>
                        <p class="Bio mt-4">
                          {" "}
                          <a href="" class="BioTitle">
                            {" "}
                            <Trans>{t('signup_review_country', {interpolation: {escapeValue: false}})}</Trans>:{" "}
                          </a>{" "}
                          {signupCountry}{" "}
                        </p>
                        <p class="Bio mt-4">
                          {" "}
                          <a href="" class="BioTitle">
                            {" "}
                            <Trans>{t('signup_review_profession', {interpolation: {escapeValue: false}})}</Trans>:{" "}
                          </a>{" "}
                          {signupProfession}{" "}
                        </p>
                      </div>
                    </div>

                    <div class="col-lg-5 SocialAssets my-4 p-4">
                      <h6 class="title mb-5"><Trans>{t('signup_review_assets', {interpolation: {escapeValue: false}})}</Trans></h6>

                      {signupSocialMediaAssets.map((asset) => (
                      <div class="social">
                        <div class="Forms ">
                          <img
                            src={loadSocialMediaIcon(asset.source)}
                            alt=""
                            class=" img-fluid"
                          />
                          <div class="my-4 input p-3">
                            <p class="label">
                              {" "}
                              {asset.name} <br /> {asset.url}{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                      ))}


                    
                    
                    </div>

                    <div class="text-end my-5">
                    <a href="#" class="btn" onClick={() => {manage(3)}}>
                        {" "}
                        <Trans>{t('signup_review_button', {interpolation: {escapeValue: false}})}</Trans>{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      )} 
      <ToastContainer
        position="top-center"
        autoClose={4000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        />
                        
        </div>
  
  );
}

export default Signup;