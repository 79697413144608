import React from 'react'
import Content2 from "../../../Images/content2.png"
import Content1 from "../../../Images/content1.png"
import { Trans, useTranslation } from 'react-i18next';

function Content() {
    const { t } = useTranslation();
  return (
    <div class="section content" id="content">
    <div class="container-fluid pb-lg-5 px-lg-5 px-md-5">
        <h2><Trans>{t('content_is_king', {interpolation: {escapeValue: false}})}</Trans></h2>
        <div class="row pt-lg-5 pb-3">
            <div class="col-lg-6 py-3 text-light">
                <h3><Trans>{t('content_is_king_title_1', {interpolation: {escapeValue: false}})}</Trans></h3>
                <p>
                <Trans>{t('content_is_king_text_1', {interpolation: {escapeValue: false}})}</Trans>
                </p>
            </div>
            <div class="col-lg-6">
                <div class="card mb-3 p-3">
                    <div class="row g-0">
                        <div class="col-md-8 order-1 order-sm-12">
                            <div class="card-body">
                            <h5 class="card-title"><span class="border border-3 p-1 py-2 border-danger rounded-circle"><Trans>{t('content_is_king_box_2_1', {interpolation: {escapeValue: false}})}</Trans></span> 
                            <Trans>{t('content_is_king_box_2_2', {interpolation: {escapeValue: false}})}</Trans></h5>
                            {/*<p class="card-text">
                            <h6>Owner name</h6> 
                                <small class="text-muted">Founder of [Company name]</small></p>*/}
                        </div>
                        </div>
                        <div class="col-md-4 order-12 order-sm-1">
                            <img src={Content1} class="img-fluid rounded-3" alt="content-image" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row pt-2">
            <div class="col-lg-6 order-1 order-sm-12">
                <div class="card mb-3 p-3" >
                    <div class="row g-0">
                        <div class="col-md-8 order-2 order-sm-11">
                            <div class="card-body">
                            <h5 class="card-title pb-3"><Trans>{t('content_is_king_box_1', {interpolation: {escapeValue: false}})}</Trans></h5>
                           {/* <p class="card-text">
                            <h6>Owner name</h6> 
                                <small class="text-muted">Founder of [Company name]</small></p>*/}
                            </div>
                        </div>
                        <div class="col-md-4 order-11 order-sm-2">
                            <img src={Content2} class="img-fluid rounded-3" alt="content-image" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 pt-3 pt-lg-5 ps-lg-5 mt-lg-5 order-1 order-sm-12">
                <h3><Trans>{t('content_is_king_title_2', {interpolation: {escapeValue: false}})}</Trans></h3>
                <p>                    
                <Trans>{t('content_is_king_text_2', {interpolation: {escapeValue: false}})}</Trans>
                </p>
            </div>
        </div>
    </div>
</div>
  )
}

export default Content