import React from 'react'
import ProcessIcon from "../../../Images/process-icon1.png"
import ProcessIcon2 from "../../../Images/process-icon2.png"
import ProcessIcon3 from "../../../Images/process-icon3.png"
import ProcessIcon4 from "../../../Images/process-icon4.png"
import ProcessIcon5 from "../../../Images/process-icon5.png"
import { Trans, useTranslation } from 'react-i18next';

function OurProcess() {
    const { t } = useTranslation();
  return (
    <div class="section process" id="process">
    <div class="container-fluid ps-lg-5 ps-md-5">
        <div class="row">
            <div class="col-lg-5">
                <h2 class="pb-3"><Trans>{t('process_title_1', {interpolation: {escapeValue: false}})}</Trans> <span class="text-primary"><Trans>{t('process_title_2', {interpolation: {escapeValue: false}})}</Trans></span></h2>
                <p>
                <Trans>{t('process_text', {interpolation: {escapeValue: false}})}</Trans>
                </p>
                <div class="row pt-4">
                    <div class="col-sm-6 pb-4">
                        <img src={ProcessIcon} alt="" />
                        <h5><Trans>{t('process_box_1_title', {interpolation: {escapeValue: false}})}</Trans></h5>
                        <p><Trans>{t('process_box_1_text', {interpolation: {escapeValue: false}})}</Trans></p>
                    </div>
                    <div class="col-sm-6 pb-4">
                        <img src={ProcessIcon2} alt="" />
                        <h5><Trans>{t('process_box_2_title', {interpolation: {escapeValue: false}})}</Trans></h5>
                        <p><Trans>{t('process_box_2_text', {interpolation: {escapeValue: false}})}</Trans></p>
                    </div>
                    <div class="col-sm-6 pb-4">
                        <img src={ProcessIcon3} alt="" />
                        <h5><Trans>{t('process_box_3_title', {interpolation: {escapeValue: false}})}</Trans></h5>
                        <p><Trans>{t('process_box_3_text', {interpolation: {escapeValue: false}})}</Trans></p>
                    </div>
                    <div class="col-sm-6 pb-4">
                        <img src={ProcessIcon4} alt="" />
                        <h5><Trans>{t('process_box_4_title', {interpolation: {escapeValue: false}})}</Trans></h5>
                        <p><Trans>{t('process_box_4_text', {interpolation: {escapeValue: false}})}</Trans></p>
                    </div>
                    <div class="col-sm-6">
                        <img src={ProcessIcon5} alt="" />
                        <h5><Trans>{t('process_box_5_title', {interpolation: {escapeValue: false}})}</Trans></h5>
                        <p><Trans>{t('process_box_5_text', {interpolation: {escapeValue: false}})}</Trans></p>
                    </div>
                </div>    
            </div>
            <div class="col-lg-7 col-sm-0 process-img">
                {/* <img src="/assets/images/process-BG.png" alt="process-img"> */}
            </div>
        </div>
    </div>
</div>
  )
}

export default OurProcess