import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BackgroundSvg1 from '../components/BackgroundImages/backgroundSvg1'
import Rankit from "../Images/Rankit-Logo.png"
import Navbar from '../components/Navbar';
import { Trans, useTranslation } from 'react-i18next';
import {config_data} from "../config.js";
import { useCookies } from 'react-cookie';

const ItPrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
<div class="dark">
<Navbar/>
<section class="signIn my-5 py-5">

<div class="container my-3">
<div class="row align-items-center">
    
<div class="col-lg-12 picture" style={{'color':'white'}}> 

<h3 class="title my-5" align="center">Privacy Policy</h3>

<p>Per noi la protezione dei dati è un argomento molto serio per cui desideriamo informarLa in merito alle modalità con le quali i dati vengono trattati ed ai diritti che Lei può esercitare ai sensi della vigente normativa sulla protezione dei dati, in particolare del Regolamento UE 2016/679 (di seguito anche: “GDPR”).</p>

<h4><strong>1. Titolare del trattamento</strong></h4>
<p>
          Mates4Digital S.r.l.<br/>
          Viale Liguria n. 24<br/>
          20143 -Milano (MI)<br/>
          Contatto e-mail: info@mates4digital.com</p>
          <h4><strong>2. Le categorie di dati che vengono sottoposti a trattamento</strong></h4>
          <p>
          I dati trattati dal Titolare sono esclusivamente i “dati personali” (ex. Art. 4.1 del GDPR).<br/>
In particolare, le categorie di dati personali attinenti possono essere, a mero titolo esemplificativo ma certo non esaustivo:<br/>
Dati anagrafici e identificativi (nome, data di nascita, luogo di nascita, nazionalità, codice fiscale, Partita Iva, estremi bancari per i pagamenti, ecc.);<br/>
Dati di contatto (indirizzo, indirizzo e-mail, indirizzo IP, numero di telefono e dati similari);<br/>
Dati relativi ai servizi erogati.
        </p>
        <h4><strong>3. Finalità coperte dal consenso dell'interessato (ex art. 6, paragrafo 1 (a) del GDPR)</strong></h4>
          <p>I dati personali possono essere trattati anche per determinate finalità per le quali l'Interessato ha dato il suo consenso.<br/>
          <b>a.</b> Rispondere a richieste o domande presentate e inviate ai contatti indicati su questo sito, per ricevere informazioni anche sui nostri servizi, nonché l’invio di preventivi e la richiesta di assistenza.<br/>
          Il periodo di <u>conservazione</u> dei dati personali, relativamente alle finalità di cui alla presente sezione è: 7 anni.<br/>
<u>Per la finalità: a, fino all’evasione della richiesta.</u>
        </p>
        <h4><strong>4. Destinatari o categorie di destinatari dei dati personali (ex art. 13 paragrafo 1 (e) del GDPR) *</strong></h4>
          <p>Nell'ambito delle suindicate finalità, il Titolare del trattamento potrà comunicare i suoi dati a:<br/>
<ul>
<li>Uffici ed alle funzioni interne del Titolare medesimo;</li>
<li>Organismi di controllo e vigilanza;</li>
<li>Società ed operatori professionali che forniscono servizi informatici, tra cui elaborazione elettronica dei dati, di gestione software, di gestione di siti e di consulenza informatica; </li>
<li>Società di mailing e hosting provider delle comunicazioni sopra indicate.</li>
</ul>
<i>* Maggiori informazioni sui Destinatari (ex art. 4.9 del GDPR) sono disponibili presso il Titolare del trattamento dei dati personali ai recapiti sopra indicati.</i>
          </p>

<h4><strong>5. Destinatari o categorie di destinatari dei dati personali (ex art. 13 paragrafo 1 (f) del GDPR)* e trasferimento dei dati in Paesi extra UE</strong></h4>
<p>Il Titolare le comunica che non ha intenzione di trasferire i suoi dati in paesi non compresi nella UE e nella SEE per le finalità sopra indicate.
</p>

<h4><strong>6. Diritti dell’interessato</strong></h4>
<p>L’interessato, in relazione ai dati personali oggetto della presente informativa, ha la facoltà di esercitare i diritti previsti dal Regolamento UE di seguito riportati:<br/>
<ul>
<li>diritto di accesso dell’interessato [art. 15 del Regolamento UE] (consistente nella possibilità di essere informato sui trattamenti effettuati sui propri dati personali ed eventualmente riceverne copia);</li>
<li>diritto di rettifica dei propri dati personali [art. 16 del Regolamento UE] (l’interessato ha diritto alla rettifica dei dati personali inesatti che lo riguardano);</li>
<li>diritto alla cancellazione dei propri dati personali senza ingiustificato ritardo (“diritto all’oblio”) [art. 17 del Regolamento UE] (l’interessato ha, così come avrà, diritto alla cancellazione dei propri dati);</li>
<li>diritto di limitazione di trattamento dei propri dati personali nei casi previsti dall’art. 18 del Regolamento UE, tra cui nel caso di trattamenti illeciti o contestazione dell’esattezza dei dati personali da parte dell’interessato [art. 18 del Regolamento UE];</li>
<li>diritto alla portabilità dei dati [art. 20 del Regolamento UE], (l’interessato potrà richiedere in formato strutturato i propri dati personali al fine di trasmetterli ad altro Titolare, nei casi previsti dal medesimo articolo);</li>
<li>diritto di opposizione al trattamento dei propri dati personali [art. 21 del Regolamento UE] (l’interessato ha, così come avrà, diritto ad opporsi al trattamento dei propri dati personali nei casi presti e regolamentati dall’art. 21 del Regolamento EU);</li>
<li>diritto di non essere sottoposto a processi decisionali automatizzati [art. 22 del Regolamento UE] (l’interessato ha, così come avrà, diritto a non essere sottoposto ad una decisione basata unicamente sul trattamento automatizzato).</li>
</ul>
<h4><strong>La descrizione sopra riportata non sostituisce il testo degli articoli ivi citati che qui si richiamano integralmente e alla cui lettura completa si rinvia all’ultima sezione di questa informativa.</strong></h4>
Relativamente alle finalità, per le quali sia richiesto il consenso, l'Interessato può revocare il proprio consenso in qualsiasi momento e gli effetti decorreranno dal momento della revoca, fatti salvi i termini previsti dalla legge. In termini generali la revoca del consenso ha effetto solo per il futuro.<br/>
I suddetti diritti possono essere esercitati secondo quanto stabilito dal Regolamento UE inviando una e-mail a indirizzo ai recapiti indicati al punto 1.<br/>
Mates4Digital S.r.l., in ossequio all’art. 19 del Regolamento UE, procede a informare i destinatari cui sono stati comunicati i dati personali, delle eventuali rettifiche, cancellazioni o limitazioni del trattamento richieste, ove ciò sia possibile.<br/>
Per consentire un più celere riscontro delle Sue richieste formulate nell'esercizio dei su indicati diritti, le stesse potranno essere rivolte al Titolare indirizzando le medesime ai recapiti indicati al punto 1.<br/>
</p>

<h4><strong>7. Diritto di proporre reclamo (ex art. 13 paragrafo 2 (d) del GDPR)</strong></h4>
<p>L’interessato, qualora ritenga che i propri diritti siano stati compromessi, ha diritto di proporre reclamo all’Autorità Garante. Per ulteriori informazioni sui diritti e sull’esercizio degli stessi si rimanda a <a href="http://www.garanteprivacy.it/web/guest/home/docweb/-/docweb-display/docweb/4535524<" target="_blank">http://www.garanteprivacy.it/web/guest/home/docweb/-/docweb-display/docweb/4535524</a> oppure inviando comunicazione scritta all’ Autorità Garante per la Protezione dei Dati Personali.
</p>

<h4><strong>8. Possibile conseguenza della mancata comunicazione dei dati e natura del conferimento dei dati (ex art. 13 paragrafo 2 (e) del GDPR)</strong></h4>
<b>8.1	In caso di consenso dell'interessato</b>
<p>Si informa che le finalità sopra esposte hanno come base giuridica il consenso e che, relativamente a tali finalità, l'Interessato può revocare il proprio consenso in qualsiasi momento e gli effetti decorreranno dal momento della revoca, fatti salvi i termini previsti dalla legge. In termini generali la revoca del consenso ha effetto solo per il futuro. Pertanto, il trattamento che è stato effettuato prima della revoca del consenso non subirà effetti e manterrà la sua legittimità.
Il mancato o parziale consenso <b>(o la revoca dello stesso)</b> potrebbe non garantire l’erogazione completa dei servizi o delle attività, con riferimento alle singole finalità per cui il consenso viene negato.<br/>
Si precisa che con riferimento alla richiesta di informazioni, pur rimanendo il consenso al trattamento dei dati personali libero e facoltativo, è necessario per l’evasione della richiesta. Quindi l’invio della richiesta o equivalente manifestazione di volontà sarà considerato come rilascio del consenso, che sarà sempre revocabile con le conseguenze sopra illustrate.
Quando i dati non sono più necessari questi vengono regolarmente cancellati, qualora la loro cancellazione risulti impossibile o possibile solo a fronte di uno sforzo sproporzionato a causa di una modalità di conservazione particolare il dato non potrà essere trattato e dovrà essere archiviato in aree non accessibili.
</p>

<h4><strong>9. Esistenza di un processo decisionale automatizzato (profilazione inclusa)</strong></h4>
<p>È attualmente escluso l'uso di processi decisionali meramente automatizzati come dettagliato dall'articolo 22 del GDPR. Se in futuro si decidesse di istituire tali processi per casi singoli, l'interessato ne riceverà notifica in separata sede qualora ciò sia previsto dalla legge o aggiornamento della presente informativa.
</p>

<h4><strong>10. Modalità del trattamento</strong></h4>
<p>I dati personali verranno trattati in forma cartacea, informatizzata e telematica ed inseriti nelle pertinenti banche dati cui potranno accedere, e quindi venirne a conoscenza, gli addetti espressamente designati dal Titolare quali Responsabili ed Autorizzati del trattamento dei dati personali, che potranno effettuare operazioni di consultazione, utilizzo, elaborazione, raffronto ed ogni altra opportuna operazione anche automatizzata nel rispetto delle disposizioni di legge necessarie a garantire, tra l'altro, la riservatezza e la sicurezza dei dati nonché l’esattezza, l’aggiornamento e la pertinenza dei dati rispetto alle finalità dichiarate.
</p>

<h4><strong>Trattamento dei dati utili ai fini della navigazione</strong></h4>
 <p> I sistemi informatici e le procedure software preposte al funzionamento di questo sito web acquisiscono, nel corso del loro normale esercizio, alcuni dati personali la cui trasmissione è implicita nell’uso dei protocolli di comunicazione di Internet.<br/>
  Si tratta di informazioni che non sono raccolte per essere associate a interessati identificati, ma che per loro stessa natura potrebbero, attraverso elaborazioni ed associazioni con dati detenuti da terzi, permettere di identificare gli utenti.
<br/>
Tra le informazioni che possono essere raccolte abbiamo gli indirizzi IP, il tipo di browser o il sistema operativo utilizzato, gli indirizzi in notazione URI (uniform resource identifier), il nome di dominio e gli indirizzi dei siti Web dai quali è stato effettuato l'accesso o l’uscita (referring/exit pages), l’orario in cui è stata fatta la richiesta al server, il metodo utilizzato e informazioni sulla risposta ottenuta, ulteriori informazioni sulla navigazione dell’utente nel sito (si veda anche la sezione relativa ai cookie) ed altri parametri relativi al sistema operativo e all’ambiente informatico dell’utente.<br/>
Questi stessi dati potrebbero, essere altresì utilizzati per individuare ed accertare le responsabilità in caso di eventuali reati informatici ai danni del sito.
</p>

<h4><strong>Avviso riguardante i minori di 14 anni</strong></h4>
<p>I minori di 14 anni non possono fornire dati personali. Mates4Digital S.r.l. non sarà in alcun modo responsabile di eventuali raccolte di dati personali, nonché di dichiarazioni mendaci, fornite dal minore, e in ogni caso, qualora se ne ravvisasse l’utilizzo, Mates4Digital S.r.l. agevolerà il diritto di accesso e di cancellazione inoltrato da parte del tutore legale o da chi ne esercita la responsabilità genitoriale. </p>

<h4><strong>Modifiche e aggiornamenti</strong></h4>
<p>La presente informativa riporta la data del suo ultimo aggiornamento nella sua intestazione.
Mates4Digital S.r.l. potrebbe inoltre apportare modifiche e/o integrazioni a detta privacy policy anche quale conseguenza di eventuali e successive modifiche e/o integrazioni normative.
</p>

<h4><strong>Riferimenti normativi sui diritti dell’interessato</strong></h4>
<p>&nbsp;</p>
<h5><strong>Articolo 15</strong></h5>
<h5><strong>Diritto di accesso dell'interessato</strong></h5>
<p>1. L'interessato ha il diritto di ottenere dal titolare del trattamento la conferma che sia o meno in corso un trattamento di dati personali che lo riguardano e in tal caso, di ottenere l'accesso ai dati personali e alle seguenti informazioni:</p>
<p>a) le finalità del trattamento;</p>
<p>b) le categorie di dati personali in questione;</p>
<p>c) i destinatari o le categorie di destinatari a cui i dati personali sono stati o saranno comunicati, in particolare se destinatari di paesi terzi o organizzazioni internazionali;</p>
<p>d) quando possibile, il periodo di conservazione dei dati personali previsto oppure, se non è possibile, i criteri utilizzati per determinare tale periodo;</p>
<p>e) l'esistenza del diritto dell’interessato di chiedere al titolare del trattamento la rettifica o la cancellazione dei dati personali o la limitazione del trattamento dei dati personali che lo riguardano o di opporsi al loro trattamento;</p>
<p>f) il diritto di proporre reclamo a un'autorità di controllo;</p>
<p>g) qualora i dati non siano raccolti presso l'interessato, tutte le informazioni disponibili sulla loro origine;</p>
<p>h) l'esistenza di un processo decisionale automatizzato, compresa la profilazione di cui all’articolo 22, paragrafi 1 e 4, e, almeno in tali casi, informazioni significative sulla logica utilizzata, nonché l'importanza e le conseguenze previste di tale trattamento per l’interessato.</p>
<p>2. Qualora i dati personali siano trasferiti a un paese terzo o a un'organizzazione internazionale, l'interessato ha il diritto di essere informato dell'esistenza di garanzie adeguate ai sensi dell'articolo 46 relative al trasferimento.</p>
<p>3. ll titolare del trattamento fornisce una copia dei dati personali oggetto di trattamento. ln caso di ulteriori copie richieste dall'interessato, il titolare del trattamento può addebitare un contributo spese ragionevole basato sui costi amministrativi. Se l’interessato presenta la richiesta mediante mezzi elettronici, e salvo indicazione diversa dell'interessato, le informazioni sono fornite in un formato elettronico di uso comune.</p>
<p>4. Il diritto di ottenere una copia di cui al paragrafo 3 non deve ledere i diritti e le libertà altrui.</p>

<h5><strong>Articolo 16</strong></h5>
<h5><strong>Diritto di rettiﬁca</strong></h5>
<p>L'interessato ha il diritto di ottenere dal titolare del trattamento la rettifica dei dati personali inesatti che lo riguardano senza ingiustificato ritardo. Tenuto conto delle finalità del trattamento, l'interessato ha il diritto di ottenere l'integrazione dei dati personali incompleti, anche fornendo una dichiarazione integrativa.
</p>

<h5><strong>Articolo 17</strong></h5>
<h5><strong>Diritto alla cancellazione («diritto all'oblio»)</strong></h5>
<p>1. L’interessato ha il diritto di ottenere dal titolare del trattamento la cancellazione dei dati personali che lo riguardano senza ingiustificato ritardo e il titolare del trattamento ha l'obbligo di cancellare senza ingiustificato ritardo i dati personali, se sussiste uno dei motivi seguenti:</p>
<p>a) i dati personali non sono più necessari rispetto alle finalità per le quali sono stati raccolti o altrimenti trattati; </p>
<p>b) l'interessato revoca il consenso su cui si basa il trattamento conformemente all'articolo 6, paragrafo 1, lettera a), o all'articolo 9, paragrafo 2, lettera a), e se non sussiste altro fondamento giuridico per il trattamento;</p>
<p>c) l'interessato si oppone al trattamento ai sensi dell'articolo 21, paragrafo 1, e non sussiste alcun motivo legittimo prevalente per procedere al trattamento, oppure si oppone al trattamento ai sensi dell'articolo 21, paragrafo 2;</p>
<p>d) i dati personali sono stati trattati illecitamente;</p>
<p>e) i dati personali devono essere cancellati per adempiere un obbligo legale previsto dal diritto dell'Unione o dello Stato membro cui è soggetto il titolare del trattamento;</p>
<p>f) i dati personali sono stati raccolti relativamente all'offerta di servizi della società dell’informazione di cui all'articolo 8, paragrafo 1.</p>
<p>2. Il titolare del trattamento, se ha reso pubblici dati personali ed è obbligato, ai sensi del paragrafo 1, a cancellarli, tenendo conto della tecnologia disponibile e dei costi di attuazione adotta le misure ragionevoli, anche tecniche, per informare i titolari del trattamento che stanno trattando i dati personali della richiesta dell’interessato di cancellare qualsiasi link, copia o riproduzione dei suoi dati personali.</p>
<p>3. I paragrafi 1 e 2 non si applicano nella misura in cui il trattamento sia necessario:</p>
<p>a) per l'esercizio del diritto alla libertà di espressione e di informazione;</p>
<p>b) per l'adempimento di un obbligo legale che richieda il trattamento previsto dal diritto dell'Unione o dello Stato membro cui È soggetto il titolare del trattamento o per l'esecuzione di un compito svolto nel pubblico interesse oppure nell'esercizio di pubblici poteri di cui è investito il titolare del trattamento;</p>
<p>c) per motivi di interesse pubblico nel settore della sanità pubblica in conformità dell'articolo 9, paragrafo 2, lettere h) e i), e dell'articolo 9, paragrafo 3;</p>
<p>d) a ﬁni di archiviazione nel pubblico interesse, di ricerca scientifica o storica o a fini statistici conformemente all'articolo 89, paragrafo 1, nella misura in cui il diritto di cui al paragrafo 1 rischi di rendere impossibile o di pregiudicare gravemente il conseguimento degli obiettivi di tale trattamento; o</p>
<p>e) per l'accertamento, l'esercizio o la difesa di un diritto in sede giudiziaria.
</p>

<h5><strong>Articolo 18</strong></h5>
<h5><strong>Diritto di limitazione di trattamento</strong></h5><p>
<p>1. L'interessato ha il diritto di ottenere dal titolare del trattamento la limitazione del trattamento quando ricorre una delle seguenti ipotesi:</p>
<p>a) l'interessato contesta l’esattezza dei dati personali, per il periodo necessario al titolare del trattamento per verificare l'esattezza di tali dati personali;</p>
<p>b) il trattamento è illecito e l'interessato si oppone alla cancellazione dei dati personali e chiede invece che ne sia limitato l'utilizzo;</p>
<p>c) benché il titolare del trattamento non ne abbia più bisogno ai fini del trattamento, i dati personali sono necessari all’interessato per l’accertamento, l'esercizio o la difesa di un diritto in sede giudiziaria;</p>
<p>d) l’interessato si è opposto al trattamento ai sensi dell’articolo 21, paragrafo 1, in attesa della verifica in merito all'eventuale prevalenza dei motivi legittimi del titolare del trattamento rispetto a quelli dell’interessato.</p>
<p>2. Se il trattamento è limitato a norma del paragrafo 1, tali dati personali sono trattati, salvo che per la conservazione, soltanto con il consenso dell'interessato o per l’accertamento, l'esercizio o la difesa di un diritto in sede giudiziaria oppure per tutelare i diritti di un'altra persona fisica o giuridica o per motivi di interesse pubblico rilevante dell'Unione o di uno Stato membro.</p>
<p>3. L’interessato che ha ottenuto la limitazione del trattamento a norma del paragrafo 1 e informato dal titolare del trattamento prima che detta limitazione sia revocata.
</p>

<h5><strong>Articolo 19</strong></h5>
<h5><strong>Obbligo di notifica in caso di rettifica o cancellazione dei dati personali o limitazione del trattamento</strong></h5>
<p>
Il titolare del trattamento comunica a ciascuno dei destinatari cui sono stati trasmessi i dati personali le eventuali rettifiche o cancellazioni o limitazioni del trattamento effettuate a norma dell'articolo 16, dell’articolo 17, paragrafo 1, e dell'articolo 18, salvo che ciò si riveli impossibile o implichi uno sforzo sproporzionato. Il titolare del trattamento comunica all’interessato tali destinatari qualora l'interessato lo richieda.
</p>

<h5><strong>Articolo 20</strong></h5>
<h5><strong>Diritto alla portabilità dei dati</strong></h5>
<p>1. L’interessato ha il diritto di ricevere in un formato strutturato, di uso comune e leggibile da dispositivo automatico i dati personali che lo riguardano forniti a un titolare del trattamento e ha il diritto di trasmettere tali dati a un altro titolare del trattamento senza impedimenti da parte del titolare del trattamento cui li ha forniti qualora:</p>
<p>a) il trattamento si basi sul consenso ai sensi dell'articolo 6, paragrafo 1, lettera a), o dell'articolo 9, paragrafo 2, lettera a), o su un contratto ai sensi dell'articolo 6, paragrafo 1, lettera b): e</p>
<p>b) il trattamento sia effettuato con mezzi automatizzati.</p>
<p>2. Nell'esercitare i propri diritti relativamente alla portabilità dei dati a norma del paragrafo 1, l’interessato ha il diritto di ottenere la trasmissione diretta dei dati personali da un titolare del trattamento all'altro, se tecnicamente fattibile.</p>
<p>3. L'esercizio del diritto di cui al paragrafo 1 del presente articolo lascia impregiudicato l'articolo 17, Tale diritto non si applica al trattamento necessario per l'esecuzione di un compito di interesse pubblico o connesso all'esercizio di pubblici poteri di cui e investito il titolare del trattamento.</p>
<p>4. ll diritto di cui al paragrafo 1 non deve ledere i diritti e le libertà altrui.</p>
</p>

<h5><strong>Articolo 21</strong></h5>
<h5><strong>Diritto di opposizione</strong></h5>
<p>1. L’interessato ha il diritto di opporsi in qualsiasi momento, per motivi connessi alla sua situazione particolare, al trattamento dei dati personali che lo riguardano ai sensi dell'articolo 6, paragrafo 1, lettere e) o f), compresa la profilazione sulla base di tali disposizioni, Il titolare del trattamento si astiene dal trattare ulteriormente i dati personali salvo che egli dimostri l'esistenza di motivi legittimi cogenti per procedere al trattamento che prevalgono sugli interessi, sui diritti e sulle libertà dell'interessato oppure per l'accertamento, l'esercizio o la difesa di un diritto in sede giudiziaria.</p>
<p>2. Qualora i dati personali siano trattati per finalità di marketing diretto, l’interessato ha il diritto di opporsi in qualsiasi momento al trattamento dei dati personali che lo riguardano effettuato per tali finalità, compresa la profilazione nella misura in cui sia connessa a tale marketing diretto.</p>
<p>3. Qualora l’interessato si opponga al trattamento per finalità di marketing diretto, i dati personali non sono più oggetto di trattamento per tali finalità.</p>
<p>4. Il diritto di cui ai paragrafi 1 e 2 è esplicitamente portato all’attenzione dell’interessato ed è presentato chiaramente e separatamente da qualsiasi altra informazione al più tardi al momento della prima comunicazione con l'interessato.</p>
<p>5. Nel contesto dell'utilizzo di servizi della società dell’informazione e fatta salva la direttiva 2002/58/CE, l'interessato può esercitare il proprio diritto di opposizione con mezzi automatizzati che utilizzano specifiche tecniche.</p>
<p>6. Qualora i dati personali siano trattati a fini di ricerca scientifica o storica o a fini statistici a norma dell'articolo 89, paragrafo 1, l’interessato, per motivi connessi alla sua situazione particolare, ha il diritto di opporsi al trattamento di dati personali che lo riguarda, salvo se il trattamento e necessario per l'esecuzione di un compito di interesse pubblico.
</p>

<h5><strong>Articolo 22</strong></h5>
<h5><strong>Processo decisionale automatizzato relativo alle persone fisiche, compresa la proﬁlazione</strong></h5>
<p>  1. L’interessato ha il diritto di non essere sottoposto a una decisione basata unicamente sul trattamento automatizzato, compresa la profilazione, che produca effetti giuridici che lo riguardano o che incida in modo analogo significativamente sulla sua persona.</p>
<p>  2. Il paragrafo 1 non si applica nel caso in cui la decisione:</p>
<p>  a) sia necessaria per la conclusione o l'esecuzione di un contratto tra l'interessato e un titolare del trattamento;</p>
<p>  b) sia autorizzata dal diritto dell'Unione o dello Stato membro cui è soggetto il titolare del trattamento, che precisa altresì misure adeguate a tutela dei diritti, delle libertà e dei legittimi interessi dell'interessato;</p>
<p>  c) si basi sul consenso esplicito dell’interessato.</p>
<p>  3, Nei casi di cui al paragrafo 2, lettere a) e c), il titolare del trattamento attua misure appropriate per tutelare i diritti, le libertà e i legittimi interessi dell'interessato, almeno il diritto di ottenere l'intervento umano da parte del titolare del trattamento, di esprimere la propria opinione e di contestare la decisione.</p>
<p>  4. Le decisioni di cui al paragrafo 2 non si basano sulle categorie particolari di dati personali di cui all'articolo 9, paragrafo 1, a meno che non sia d'applicazione l'articolo 9, paragrafo 2, lettere a) o g), e non siano in vigore misure adeguate a tutela dei diritti, delle libertà e dei legittimi interessi dell’interessato. </p>

</div>

{/*<div class="col-lg-6 SignIn Forms px-5">

    <h3 class="title my-5">
    <Trans>{t('signin_title', {interpolation: {escapeValue: false}})}</Trans>
    </h3>


    <div class="form-floating my-4">
        <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com" value={signinEmail} onChange={e => setSigninEmail(e.target.value)} />
        <label for="floatingInput"><Trans>{t('signin_email', {interpolation: {escapeValue: false}})}</Trans></label>
      </div>

      <div class="form-floating my-3">
        <input type="password" class="form-control" id="floatingPassword" placeholder="Password" value={signinPassword} onChange={e => setSigninPassword(e.target.value)} />
        <label for="floatingPassword"><Trans>{t('signin_password', {interpolation: {escapeValue: false}})}</Trans></label>
      </div>

      {/*<div class="form-check">
        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
        <label class="form-check-label" for="flexCheckDefault">
        <Trans>{t('signin_remember_me', {interpolation: {escapeValue: false}})}</Trans>
        </label>
  </div>
      
      <button class="btn my-4" onClick={() => {signin()}}><Trans>{t('signin_button', {interpolation: {escapeValue: false}})}</Trans></button>


      <div>
        <a href="/signup" class="my-2 link"><Trans>{t('signin_signup', {interpolation: {escapeValue: false}})}</Trans></a>
      </div>

      <div class="my-4 ">
        <a href="/forgotpassword" class="my-4 link"><Trans>{t('signin_forgot', {interpolation: {escapeValue: false}})}</Trans></a>
      </div>

</div>*/}
</div>

</div>

    </section>
    <ToastContainer
        position="top-center"
        autoClose={4000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        />
    </div>
    
  )
};

export default ItPrivacyPolicy;