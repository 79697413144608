import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BackgroundSvg1 from '../components/BackgroundImages/backgroundSvg1'
import Rankit from "../Images/Rankit-Logo.png"
import Navbar from '../components/Navbar';
import { Trans, useTranslation } from 'react-i18next';
import {config_data} from "../config.js";
import { useCookies } from 'react-cookie';

const EnPrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
<div class="dark">
<Navbar/>
<section class="signIn my-5 py-5">

<div class="container my-3">
<div class="row align-items-center">
    
<div class="col-lg-12 picture" style={{'color':'white'}}> 

<h3 class="title my-5" align="center">FAQ</h3>


 <h4><strong>1. What is Rankit and how does it work?</strong></h4>
 
 <p class="c6"><span class="c2">Rankit is a digital platform that allows you to connect with our customers
by participating in campaigns on the platform. The registration at Rankit
is non-binding and above all does not imply an exclusive relationship. You
decide when and what type of campaign to apply for and join, in total
freedom and autonomy.</span></p>

<h4><strong>2. How can I earn with Rankit?</strong></h4>
 
 <p class="c6"><span class="c2">With Rankit you have the opportunity to earn by joining campaigns on
our platform. There are 4 types of campaigns: CPC, where you will be
remunerated for the number of clicks you manage to get; CPL where you
will be compensated based on the number of leads your share link will
generate and finally the Creative and Native projects, where for the
remuneration you will propose a reference budget for the campaign and
the client will decide whether to accept it, depending on the budget
available.</span></p>

<h4><strong>3. What types of social networks are supported by Rankit?</strong></h4>
 <p class="c6"><span class="c2">The social networks supported by Rankit are: LinkedIn, Instagram,
Facebook, TikTok, Twitter and Twitch.</span></p>

<h4><strong>4. How can I join a campaign on Rankit?</strong></h4>
 <p class="c6"><span class="c2">To participate in a campaign on Rankit you need to enter the "Active
Campaigns" section and select the type of campaign you would like to
join.</span></p>

<h4><strong>5. How can I monitor the performance of my campaign on Rankit?</strong></h4>
 <p class="c6"><span class="c2">You can monitor the performance of the campaigns you have joined in
the Report section, simply select the campaign you wish to investigate
and view the reporting period.</span></p>

<h4><strong>6. How can I update my profile on Rankit?</strong></h4>
 <p class="c6"><span class="c2">You can edit and update your profile effortlessly in the dashboard. In the
top right-hand corner, you will find your username and your photo, by
clicking you will find the "Profile" section. Here you can update your
profile whenever you wish.</span></p>

<h4><strong>7. Why do I have to indicate my socials?</strong></h4>
 <p class="c6"><span class="c2">It is important to indicate your socials, because in this way, for the
Creative and Native campaigns, the client will be able to view your profile
and choose you for the campaign you have applied for. For CPC and CPL
campaigns you can publish the links directly from the platform.</span></p>

<h4><strong>8. What is billing information?</strong></h4>
 <p class="c6"><span class="c2">Billing information is the information that Rankit needs, such as IBAN and
Tax ID/VAT number, in order to process payments to you.</span></p>

<h4><strong>9. Can I use occasional professional services to earn with Rankit?</strong></h4>
 <p class="c6"><span class="c2">Yes, there is no problem.</span></p>

<h4><strong>10. Where can I see my payment history on Rankit?</strong></h4>
 <p class="c6"><span class="c2">You can view your payment history in the "Payments" section, which can
be found in the drop-down menu on the left of the page.</span></p>

<h4><strong>11. What is the Report section for?</strong></h4>
 <p class="c6"><span class="c2">The Report section is where you can find the progress of all your
campaigns.</span></p>

<h4><strong>12. Is there a limit to how much I can earn with Rankit?</strong></h4>
 <p class="c6"><span class="c2">There is no limit on how much you can earn.</span></p>

<h4><strong>13. Can I edit the content after approval?</strong></h4>
 <p class="c6"><span class="c2">After obtaining the customer&#39;s approval, no content can be changed.</span></p>

<h4><strong>14. Do I have to be an adult to use Rankit?</strong></h4>
 <p class="c6"><span class="c2">Yes, you must be 18 years old and over.</span></p>

<h4><strong>15. In which languages is Rankit available?</strong></h4>
 <p class="c6"><span class="c2">Italian and English.</span></p>

<h4><strong>16. In which countries is Rankit available?</strong></h4>
 <p class="c6"><span class="c2">In Italy, for the moment.</span></p>

<h4><strong>17. What is Rankit&#39;s Privacy Policy?</strong></h4>
 <p class="c6"><span class="c2">Rankit&#39;s Privacy Policy is explained at this link: <a href="https://rankit.it/privacy-policy-en">https://rankit.it/privacy-policy-en</a></span></p>

<h4><strong>18. How are cookies used on Rankit?</strong></h4>
 <p class="c6"><span class="c2">Only technical cookies are used on Rankit, which are useful to enhance
the performance of certain sections of the platform. For more
information: <a href="https://rankit.it/cookie-policy-en">https://rankit.it/cookie-policy-en</a></span></p>

<h4><strong>19. What data is stored on Rankit?</strong></h4>
 <p class="c6"><span class="c2">Personal and identification data (first name, last name, etc.); contact data
(address, e-mail address, IP address, telephone number and similar data);
details of any services provided.</span></p>

<h4><strong>20. Can I request to delete my account and its data?</strong></h4>
 <p class="c6"><span class="c2">You can decide to delete your account at any time by going to the "Profile"
section and then clicking on "Delete Profile".</span></p>

<h4><strong>21. Which campaigns can I find within the platform?</strong></h4>
 <p class="c6"><span class="c2">Within Rankit you can find 4 campaign types:<br />
<b>A. CPC campaigns:</b> you can share an article on your social profiles. You
will be remunerated according to the number of clicks you manage to
bring in.<br />
<b>B. CPL campaigns:</b> you can share a specific landing page on your social
profiles. You will be compensated according to the number of leads you
manage to generate.<br />
<b>C. Native Campaigns:</b> you can apply for our Native campaigns, which will
allow you to share a creative on your social profiles that will be provided
directly by the Rankit team. In this case, subject to approval, you will be
remunerated in agreement with the Rankit team.<br />
<b>D. Creative Campaigns:</b> you will have the opportunity to be selected for a
campaign in which you will, following a precise brief provided by us,
create ad hoc social content for our clients based on their objectives and
instructions. In this case, you will be compensated in agreement with the
Rankit team.</span></p>

<h4><strong>22. Where can I find the active campaigns on the platform?</strong></h4>
 <p class="c6"><span class="c2">To find active campaigns, simply log into the platform with your
credentials and click on "Active Campaigns" in the side menu.</span></p>

<h4><strong>23. How can I join a campaign?</strong></h4>
 <p class="c6"><span class="c2">To join a CPC and CPL campaign, simply go to "Active Campaigns". select
the one you wish to join and click the green button "Join". For Native and
Creative campaigns, on the other hand, you will not be able to join
independently, you will have to apply and only afterwards, subject to
client approval, will you be able to take part in the campaign.</span></p>

<h4><strong>24. How do I know if a CPC or a CPL campaign is still available?</strong></h4>
 <p class="c6"><span class="c2">Each CPC and CPL campaign has a traffic light on the left-hand side. If it
is green, it means that there is still budget available and you can join and
start sharing.</span></p>

<h4><strong>25. What happens if I see a red light in a CPC or a CPL campaign?</strong></h4>
 <p class="c6"><span class="c2">It means that although the campaign has not yet expired in terms of
time, the available budget has finished and no further clicks will be
registered from that point forward.</span></p>

<h4><strong>26. What happens if I see a yellow traffic light?</strong></h4>
 <p class="c6"><span class="c2">A yellow traffic light means that the campaign budget is running out.</span></p>

<h4><strong>27. If I need assistance, who can I contact?</strong></h4>
 <p class="c6"><span class="c2">Within the platform, you will find a chat that will allow you to
communicate directly with the Rankit team, who will be able to support
you with anything that comes up.</span></p>

<h4><strong>28. How do I know how much I am earning?</strong></h4>
 <p class="c6"><span class="c2">Within the platform, in your personal section, you can keep track of your
earnings, campaign by campaign.</span></p>

<h4><strong>29. Where do I see how much I can earn from a CPC campaign before I join?</strong></h4>
 <p class="c6"><span class="c2">Before joining a CPC campaign, you have the option of clicking the
"Preview" button, which allows you to see all the details of the campaign,
such as the earnings per click and the duration of the campaign.</span></p>

<h4><strong>30. Where do I see how much I can earn from a CPL campaign before I join?</strong></h4>
 <p class="c6"><span class="c2">Before joining a CPL campaign, you have the option of clicking the
"Preview" button, which will allow you to see all the details of the
campaign, such as the earnings per lead and the duration of the
campaign.</span></p>

<h4><strong>31. Where do I see how much I can earn from a Native campaign?</strong></h4>
 <p class="c6"><span class="c2">Native campaigns do not have a fixed budget. Once you have viewed the
campaign preview, you will have the opportunity to submit your
application. Before confirming it and sending it to the client, who can
either reject or approve it, you will be asked to enter your fee in relation to
the amount of content to be published. The client will then be able to
decide whether your request is in line with their available budget or
propose a different fee in order to reach an agreement.</span></p>

<h4><strong>32. Where do I see how much I can earn from a Creative campaign?</strong></h4>
 <p class="c6"><span class="c2">Creative campaigns do not have a fixed budget. Once you have viewed
the campaign preview, you will have the opportunity to submit your
application. Before confirming it and sending it to the client, who can
either reject it or approve it, you will be asked to enter your fee in relation
to the amount of content to be published. The client will then be able to
decide whether your request is in line with their available budget or
propose a different fee in order to reach an agreement.</span></p>

<h4><strong>33. How does the payment process work for the campaigns I have joined?</strong></h4>
 <p class="c6"><span class="c2">When you sign up for the platform, you will have to enter your billing
information before proceeding to join and share campaigns. You will then
find the &#39;Payments&#39; section in the side menu. Here you can manage and
monitor the status of the delivery, processing and fulfilment of your
payment request.</span></p>

<h4><strong>34. When can I request payment?</strong></h4>
 <p class="c6"><span class="c2">You can request payment once you have reached a total earning of 50€.
The payment request must be made directly on the platform, following
the instructions in the "Payments" section.</span></p>

<h4><strong>35. I have requested payment but I still see no money in my account</strong></h4>
 <p class="c6"><span class="c2">Payment requests received by the 5th day of the current month following
the end of the assignment will be paid 90 days within the 10th day of that
month.<br />
Payment requests received after the 5th day of the current month
following the end of the assignment will be paid 90 days within the end of
the following month.</span></p>

<h4><strong>36. I don't have a VAT number, can I use Rankit?</strong></h4>
 <p class="c6"><span class="c2">Yes, we can collaborate through occasional professional services.</span></p>

<h4><strong>37. Does content created for Creative campaigns have to be approved by the client?</strong></h4>
 <p class="c6"><span class="c2">That's right, all content created for Creative campaigns must be uploaded
to the platform and submitted for approval by the client, before being
published on your social profiles.</span></p>

<h4><strong>38. How long should I leave content posted on my profile?</strong></h4>
 <p class="c6"><span class="c2">In regard to Instagram Stories, they should remain on the profile for the
entire organic duration. As for the other content types (posts, reels,
tweets), they must remain on the profile at least until the end of the
campaign, subject to different provisions indicated in the campaign
preview.</span></p>

</div>

{/*<div class="col-lg-6 SignIn Forms px-5">

    <h3 class="title my-5">
    <Trans>{t('signin_title', {interpolation: {escapeValue: false}})}</Trans>
    </h3>


    <div class="form-floating my-4">
        <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com" value={signinEmail} onChange={e => setSigninEmail(e.target.value)} />
        <label for="floatingInput"><Trans>{t('signin_email', {interpolation: {escapeValue: false}})}</Trans></label>
      </div>

      <div class="form-floating my-3">
        <input type="password" class="form-control" id="floatingPassword" placeholder="Password" value={signinPassword} onChange={e => setSigninPassword(e.target.value)} />
        <label for="floatingPassword"><Trans>{t('signin_password', {interpolation: {escapeValue: false}})}</Trans></label>
      </div>

      {/*<div class="form-check">
        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
        <label class="form-check-label" for="flexCheckDefault">
        <Trans>{t('signin_remember_me', {interpolation: {escapeValue: false}})}</Trans>
        </label>
  </div>
      
      <button class="btn my-4" onClick={() => {signin()}}><Trans>{t('signin_button', {interpolation: {escapeValue: false}})}</Trans></button>


      <div>
        <a href="/signup" class="my-2 link"><Trans>{t('signin_signup', {interpolation: {escapeValue: false}})}</Trans></a>
      </div>

      <div class="my-4 ">
        <a href="/forgotpassword" class="my-4 link"><Trans>{t('signin_forgot', {interpolation: {escapeValue: false}})}</Trans></a>
      </div>

</div>*/}
</div>

</div>

    </section>
    <ToastContainer
        position="top-center"
        autoClose={4000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        />
    </div>
    
  )
};

export default EnPrivacyPolicy;