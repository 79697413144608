import React from 'react'
import logo from "../../Images/Rankit-Logo.png"
import "../../App.css"
import { Trans, useTranslation } from 'react-i18next';
import i18next from 'i18next';


function Navbar() {
  const { t } = useTranslation();
  return (
    <>
      <nav class="navbar navbar-expand-lg fixed-top" aria-label="Offcanvas navbar large">
        <div class="container-fluid px-lg-5 px-md-5">
          <a class="navbar-brand" href="/"><img src={logo} alt="Rankit-Logo" /></a>
            {/* <label class="switch switch-nav d-flex justify-content-end">
                <input type="checkbox">
                <span class="slider round"></span>
            </label> --> */}
          <span class="navbar-toggler border-none" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar2" aria-controls="offcanvasNavbar2">
            {/* <!-- <span class="navbar-toggler-icon"></span> --> */}
            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-filter-right" viewBox="0 0 16 16">
                <path d="M14 10.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 .5-.5zm0-3a.5.5 0 0 0-.5-.5h-7a.5.5 0 0 0 0 1h7a.5.5 0 0 0 .5-.5zm0-3a.5.5 0 0 0-.5-.5h-11a.5.5 0 0 0 0 1h11a.5.5 0 0 0 .5-.5z"></path>
            </svg>
          </span>
          <div id="offcanvasNavbar2" class="offcanvas offcanvas-end" data-bs-backdrop="false" tabindex="-1" aria-labelledby="offcanvasNavbar2Label">
            <div class="offcanvas-header ">
              <h5 class="offcanvas-title" id="offcanvasNavbar2Label">Rankit</h5>
              <button type="button" class="btn-close btn-close shadow-sm bg-light" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div class="offcanvas-body">
              <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
                <li class="nav-item" data-bs-dismiss="offcanvas">
                  <a class="nav-link active" href="/"><Trans>{t('navbar_home', {interpolation: {escapeValue: false}})}</Trans></a>
                </li>
                <li class="nav-item" data-bs-dismiss="offcanvas">
                  <a class="nav-link" href="/#about"><Trans>{t('navbar_about_us', {interpolation: {escapeValue: false}})}</Trans></a>
                </li>
                <li class="nav-item" data-bs-dismiss="offcanvas">
                    <a class="nav-link" href="/#news"><Trans>{t('navbar_news', {interpolation: {escapeValue: false}})}</Trans></a>
                </li>
                <li class="nav-item mb-2" data-bs-dismiss="offcanvas">
                  <a class="nav-link" href={t('home_faq_url')}>FAQ</a>
                </li>
                <li class="nav-item mb-2" data-bs-dismiss="offcanvas">
                  <a class="nav-link" href="/#contact"><Trans>{t('navbar_contact_us', {interpolation: {escapeValue: false}})}</Trans></a>
                </li>
                <li class="nav-item mb-2" data-bs-dismiss="offcanvas">
                  <a class="nav-link" onClick={() => {i18next.changeLanguage('it')}} style={{cursor:'pointer'}}>Italiano</a>
                </li>
                <li class="nav-item mb-2" data-bs-dismiss="offcanvas">
                  <a class="nav-link" onClick={() => {i18next.changeLanguage('en')}} style={{cursor:'pointer'}}>English</a>
                </li>
                <li class="nav-item pt-3">
                    <a href="/signin" class="btn-login"><Trans>{t('navbar_signin', {interpolation: {escapeValue: false}})}</Trans></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
    </nav>
    </>
  )
}

export default Navbar