import React from 'react'
import News1 from "../../Images/news1.png"
import News2 from "../../Images/news2.png"
import News3 from "../../Images/news3.png"
import { Trans, useTranslation } from 'react-i18next';

function News() {
    const { t } = useTranslation();
  return (
    <div class="section news" id="news">
        <div class="container-fluid py-0 px-lg-5 px-md-5">
            <h2><Trans>{t('news_title_1', {interpolation: {escapeValue: false}})}</Trans> <span class="text-primary"><Trans>{t('news_title_2', {interpolation: {escapeValue: false}})}</Trans></span></h2>
            <div class="row pt-4">
                <div class="col-lg-4">
                    <div class="card mb-5">
                        <img src={News1} class="card-img-top m-0 p-0" alt="..." />
                        <div class="card-body">
                            <h4 class="card-title"><Trans>{t('news_article_1_title', {interpolation: {escapeValue: false}})}</Trans></h4>
                            <p class="card-text py-2">
                            <Trans>{t('news_article_1_text', {interpolation: {escapeValue: false}})}</Trans>
                            </p>
                            <a href="/"><h5 class="card-text"><small class="text-primary">Business</small></h5></a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="card mb-5">
                        <img src={News2} class="card-img-top m-0 p-0" alt="..." />
                        <div class="card-body">
                        <h4 class="card-title"><Trans>{t('news_article_2_title', {interpolation: {escapeValue: false}})}</Trans></h4>
                        <p class="card-text py-2">
                        <Trans>{t('news_article_2_text', {interpolation: {escapeValue: false}})}</Trans>
                        </p>
                        <a href="/"><h5 class="card-text"><small class="text-primary">Business</small></h5></a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="card">
                        <img src={News3} class="card-img-top m-0 p-0" alt="..." />
                        <div class="card-body">
                            <h4 class="card-title"><Trans>{t('news_article_3_title', {interpolation: {escapeValue: false}})}</Trans></h4>
                            <p class="card-text py-2">
                            <Trans>{t('news_article_3_text', {interpolation: {escapeValue: false}})}</Trans>
                            </p>
                            <a href="/"><h5 class="card-text"><small class="text-primary">Business</small></h5></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default News