import React from 'react'
import SocialIcon from "../../../Images/Social-Icon-GIF.png"
import Social1 from "../../../Images/Social-Icon1.png"
import Social2 from "../../../Images/Social-Icon2.png"
import Social3 from "../../../Images/Social-Icon3.png"
import { Trans, useTranslation } from 'react-i18next';


function Social() {
    const { t } = useTranslation();
  return (
    <div class="section contextual" id="contextual">
    <div class="container-fluid px-lg-5 px-md-5">
        <div class="row">
            <div class="col-lg-7 pt-lg-5">
                <h2 class="pb-3"><span class="text-primary"><Trans>{t('social_title_1', {interpolation: {escapeValue: false}})}</Trans></span> <Trans>{t('social_title_2', {interpolation: {escapeValue: false}})}</Trans></h2>
                <p>
                <Trans>{t('social_text', {interpolation: {escapeValue: false}})}</Trans>
                </p>
            </div>
            <div class="col-lg-5">
                <div class="card mb-lg-4 mb-5 p-2" >
                    <div class="row g-0">
                        <div class="col-md-3">
                            <img src={Social1} class="img-fluid rounded-circle" alt="content-image" />
                        </div>
                        <div class="col-md-9">
                            <div class="card-body">
                                <p class="card-text"><Trans>{t('social_box_1', {interpolation: {escapeValue: false}})}</Trans></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card mb-lg-4 mb-5 p-2" >
                    <div class="row g-0">
                        <div class="col-md-3">
                            <img src={Social2} class="img-fluid rounded-circle" alt="content-image" />
                        </div>
                        <div class="col-md-9">
                            <div class="card-body">
                                <p class="card-text"><Trans>{t('social_box_2', {interpolation: {escapeValue: false}})}</Trans></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card mb-lg-4 mb-5 p-2" >
                    <div class="row g-0">
                        <div class="col-md-3">
                            <img src={Social3} class="img-fluid rounded-circle" alt="content-image" />
                        </div>
                        <div class="col-md-9">
                            <div class="card-body">
                                <p class="card-text"><Trans>{t('social_box_3', {interpolation: {escapeValue: false}})}</Trans></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-between">
                <span></span>
                <a href="/signin"><button class="btn btn-connect my-lg-5 text-center"><Trans>{t('social_button', {interpolation: {escapeValue: false}})}</Trans></button></a>
                <a href="#"><img src={SocialIcon} class="img-fluid float-icon" alt="content-image" /></a>
            </div>
        </div>
    </div>
</div>
  )
}

export default Social