import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BackgroundSvg1 from '../components/BackgroundImages/backgroundSvg1'
import Rankit from "../Images/Rankit-Logo.png"
import Navbar from '../components/Navbar';
import { Trans, useTranslation } from 'react-i18next';
import {config_data} from "../config.js";
import { useCookies } from 'react-cookie';

const EnPrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
<div class="dark">
<Navbar/>
<section class="signIn my-5 py-5">

<div class="container my-3">
<div class="row align-items-center">
    
<div class="col-lg-12 picture" style={{'color':'white'}}> 

<h3 class="title my-5" align="center">FAQ</h3>


 <h4><strong>1. Cos'è Rankit e come funziona?</strong></h4>
 
 <p class="c6"><span class="c2">Rankit è una piattaforma digitale che ti permetterà di entrare in contatto
con i nostri clienti, partecipando alle campagne presenti in piattaforma.
L'iscrizione a Rankit non è vincolante e soprattutto non implica un
rapporto di esclusività. Decidi tu quando e a cosa aderire e a quale
campagna candidarti, in totale libertà e autonomia.</span></p>

<h4><strong>2. Come posso guadagnare con Rankit?</strong></h4>
 
 <p class="c6"><span class="c2">Grazie a Rankit hai la possibilità di guadagnare aderendo alle campagne
presenti sulla nostra piattaforma. Le campagne sono di 4 tipi: CPC, dove
sarai remunerat* per il numero di clic che riesci ad ottenere; CPL dove
sarai remunerat* in base al numero di lead che la tua condivisione
genererà e infine i progetti Creative e Native, dove invece per la
remunerazione sarai tu a proporre un budget di riferimento per la
campagna e il cliente a decidere se accettarlo, in base al budget a
disposizione.</span></p>

<h4><strong>3. Quali tipi di social network sono supportati da Rankit?</strong></h4>
 <p class="c6"><span class="c2">I social supportati da Rankit sono: LinkedIn, Instagram, Facebook, TikTok,
Twitter e Twitch.</span></p>

<h4><strong>4. Come posso partecipare ad una campagna su Rankit?</strong></h4>
 <p class="c6"><span class="c2">Per partecipare a una campagna su Rankit devi entrare nella sezione
"Campagne attive" e selezionare il tipo di campagna a cui vorresti aderire.</span></p>

<h4><strong>5. Come posso monitorare le performance della mia campagna su Rankit?</strong></h4>
 <p class="c6"><span class="c2">Puoi monitorare le performance delle campagne a cui hai aderito nella
sezione Report, dovrai semplicemente selezionare la campagna che
desideri approfondire e visionare il periodo di riferimento.</span></p>

<h4><strong>6. Come posso aggiornare il mio profilo su Rankit?</strong></h4>
 <p class="c6"><span class="c2">Puoi modificare e aggiornare il tuo profilo comodamente nella
dashboard. In alto a destra, trovi il tuo nome utente e la tua foto, cliccando
avrai la possibilità di trovare la sezione "Profile". Qui potrai aggiornare il
tuo profilo ogni volta che lo desideri.</span></p>

<h4><strong>7. Perché devo indicare i miei social?</strong></h4>
 <p class="c6"><span class="c2">È importante indicare i tuoi social, perché in questo modo, nelle
campagne Creative e Native il cliente potrà osservare il tuo profilo e
sceglierti per la campagna a cui ti sei candidat*. Per quanto riguarda le
campagne CPC e CPL potrai pubblicare i link direttamente dalla
piattaforma.</span></p>

<h4><strong>8. Cosa sono le informazioni per la fatturazione?</strong></h4>
 <p class="c6"><span class="c2">Le informazioni per la fatturazione sono i dati utili a Rankit, come IBAN e
Codice Fiscale/ P.IVA, per poter procedere con i pagamenti a tuo favore.</span></p>

<h4><strong>9. Posso usare la ritenuta d'acconto per guadagnare con Rankit?</strong></h4>
 <p class="c6"><span class="c2">Si, non c'è nessun problema.</span></p>

<h4><strong>10. Dove posso vedere lo storico dei miei pagamenti su Rankit?</strong></h4>
 <p class="c6"><span class="c2">Lo storico dei pagamenti lo puoi trovare nella sezione "Pagamenti", che si
trova nel menù a tendina a sinistra della pagina.</span></p>

<h4><strong>11. A cosa serve la sezione Report?</strong></h4>
 <p class="c6"><span class="c2">La sezione Report è dove puoi vedere l'andamento di tutte le tue
campagne a cui hai aderito.</span></p>

<h4><strong>12. C'è un limite a quanto posso guadagnare con Rankit?</strong></h4>
 <p class="c6"><span class="c2">Non c'è nessun limite di guadagno.</span></p>

<h4><strong>13. Posso modificare un contenuto dopo l'approvazione?</strong></h4>
 <p class="c6"><span class="c2">Dopo aver ottenuto l'approvazione da parte del cliente, nessun contenuto
potrà essere modificato.</span></p>

<h4><strong>14. Devo essere maggiorenne per usare Rankit?</strong></h4>
 <p class="c6"><span class="c2">Si, devi essere maggiorenne.</span></p>

<h4><strong>15. In quali lingue è disponibile Rankit?</strong></h4>
 <p class="c6"><span class="c2">Italiano e Inglese.</span></p>

<h4><strong>16. In quali paesi è disponibile Rankit?</strong></h4>
 <p class="c6"><span class="c2">Italia, per il momento.</span></p>

<h4><strong>17. Qual è la privacy policy di Rankit?</strong></h4>
 <p class="c6"><span class="c2">La privacy Policy di Rankit è spiegata a questo link: <a href="https://rankit.it/privacy-policy-it">https://rankit.it/privacy-policy-it</a></span></p>

<h4><strong>18. Come vengono usati i cookie su Rankit?</strong></h4>
 <p class="c6"><span class="c2">Su Rankit vengono utilizzati solo i cookie tecnici, utili per il corretto
funzionamento di alcune sezioni della piattaforma. Per ulteriori
informazioni: <a href="https://rankit.it/cookie-policy-it">https://rankit.it/cookie-policy-it</a></span></p>

<h4><strong>19. Quali dati vengono salvati su Rankit?</strong></h4>
 <p class="c6"><span class="c2">Dati personali e identificativi (nome, cognome, ecc.); dati di contatto
(indirizzo, indirizzo e-mail, indirizzo IP, numero di telefono e dati simili);
dettagli su eventuali servizi forniti.</span></p>

<h4><strong>20. Posso chiedere la cancellazione del mio account e dei suoi dati?</strong></h4>
 <p class="c6"><span class="c2">Puoi decidere di cancellare il tuo account in qualsiasi momento, nella
sezione "Profilo" e cliccando successivamente su "Cancella profilo".</span></p>

<h4><strong>21. Quali campagne posso trovare all'interno della piattaforma?</strong></h4>
 <p class="c6"><span class="c2">All'interno di Rankit potrai trovare 4 tipologie di campagne:<br />
<b>A. Campagne a CPC:</b> potrai condividere un articolo sui tuoi profili social.
Sarai remunerat* in base al numero di clic che riuscirai a portare.<br />
<b>B. Campagne a CPL:</b> potrai condividere una landing specifica sui tuoi
profili social. Sarai remunerat* in base al numero di lead che riuscirai a
portare.<br />
<b>C. Campagne Native:</b> potrai candidarti alle nostre campagne Native, che
ti permetteranno di condividere sui tuoi profili social una creatività che ti
verrà fornita direttamente dal team di Rankit. In questo caso, previa
approvazione, sarai remunerat* in accordo con il team di Rankit.<br />
<b>D. Campagne Creative:</b> avrai l'opportunità di essere selezionato per una
campagna nella quale dovrai, dietro a un brief preciso fornito da noi,
realizzare dei contenuti social ad hoc per i nostri clienti in base ai loro
obiettivi e istruzioni. In questo caso, sarai remunerat* in accordo con il
team di Rankit.</span></p>

<h4><strong>22. Dove posso trovare le campagne attive in piattaforma?</strong></h4>
 <p class="c6"><span class="c2">Per trovare le campagne attive, ti basterà entrare in piattaforma con le
tue credenziali e cliccare nel menù laterale la sezione "Campagne attive".</span></p>

<h4><strong>23. Come posso aderire a una campagna?</strong></h4>
 <p class="c6"><span class="c2">Per aderire a una campagna CPC e CPL, ti basterà andare nella sezione
<b>"Campagne attive"</b>, selezionare quella a cui desideri aderire e clicca il
bottone verde <b>"Join"</b>. Per le campagne Native e Creative invece, non
potrai aderire in autonomia, dovrai candidarti e solo successivamente,
previa approvazione del cliente, potrai prendere parte alla campagna.</span></p>

<h4><strong>24. Come faccio a sapere se una campagna CPC e CPL è ancora disponibile?</strong></h4>
 <p class="c6"><span class="c2">Ogni campagna a CPC e CPL ha un semaforo sulla sinistra. Se è verde
significa che c'è ancora del budget disponibile e potrai aderire e iniziare a
condividerla.</span></p>

<h4><strong>25. Cosa succede se in una campagna CPC e CPL vedo un semaforo rosso?</strong></h4>
 <p class="c6"><span class="c2">Significa che nonostante la campagna non sia ancora scaduta a livello
temporale, il budget messo a disposizione è terminato e non saranno più
registrati i clic che verranno realizzati da quel momento in poi.</span></p>

<h4><strong>26. Cosa succede se vedo semaforo giallo?</strong></h4>
 <p class="c6"><span class="c2">Il semaforo giallo significa che il budget a disposizione per la campagna
sta per terminare.</span></p>

<h4><strong>27. Se ho bisogno di assistenza, a chi mi posso rivolgere?</strong></h4>
 <p class="c6"><span class="c2">All'interno della piattaforma troverai una chat che ti permetterà di
comunicare direttamente con il team di Rankit, che in poco tempo saprà
esserti di supporto per ogni evenienza.</span></p>

<h4><strong>28. Come faccio a sapere quanto sto guadagnando?</strong></h4>
 <p class="c6"><span class="c2">All'interno della piattaforma, nella tua sezione personale, avrai modo di
tenere traccia dei tuoi guadagni, campagna per campagna.</span></p>

<h4><strong>29. Dove vedo quanto posso guadagnare da una campagna CPC prima di aderire?</strong></h4>
 <p class="c6"><span class="c2">Prima di aderire alle campagne CPC, hai la possibilità di cliccare il
pulsante "Preview" che ti permette di visualizzare tutti i dettagli relativi
alla campagna, come il guadagno per singolo clic e la durata della
campagna.</span></p>

<h4><strong>30. Dove vedo quanto posso guadagnare da una campagna CPL prima di aderire?</strong></h4>
 <p class="c6"><span class="c2">Prima di aderire alle campagne CPL, hai la possibilità di cliccare il
pulsante "Preview" che ti permetterà di visualizzare tutti i dettagli relativi
alla campagna, come il guadagno per singola lead e la durata della
campagna stessa.</span></p>

<h4><strong>31. Dove vedo quanto posso guadagnare da una campagna Native?</strong></h4>
 <p class="c6"><span class="c2">Le campagne Native non hanno un budget fisso. Una volta visionata la
preview della campagna, avrai la possibilità di inviare la tua candidatura.
Prima di confermarla e di inviarla al cliente, che potrà rifiutarla o
approvarla, ti sarà richiesto di inserire la tua fee in relazione al numero di
contenuti da pubblicare. Il cliente potrà decidere se la tua richiesta è in
linea con il suo budget disponibile oppure proporti una fee differente per
trovare un accordo.</span></p>

<h4><strong>32. Dove vedo quanto posso guadagnare da una campagna Creative?</strong></h4>
 <p class="c6"><span class="c2">Le campagne Creative non hanno un budget fisso. Una volta visionata la
preview della campagna, avrai la possibilità di inviare la tua candidatura.
Prima di confermarla e di inviarla al cliente, che potrà rifiutarla o
approvarla, ti sarà richiesto di inserire la tua fee in relazione al numero di
contenuti da pubblicare. Il cliente potrà decidere se la tua richiesta è in
linea con il suo budget disponibile oppure proporti una fee differente per
trovare un accordo.</span></p>

<h4><strong>33. Come funziona per il pagamento delle campagne a cui ho aderito?</strong></h4>
 <p class="c6"><span class="c2">Al momento dell'iscrizione alla piattaforma, prima di procedere con la
condivisione delle campagne, dovrai inserire i tuoi dati di fatturazione.
Successivamente, nel menu laterale, troverai la sezione "Pagamenti".
Qui potrai gestire e monitorare lo stato di consegna, lavorazione ed
evasione della tua richiesta di pagamento.</span></p>

<h4><strong>34. Quando posso richiedere il pagamento?</strong></h4>
 <p class="c6"><span class="c2">Potrai richiedere il pagamento una volta raggiunto un guadagno totale di
50€. La richiesta di pagamento dev'essere effettuata direttamente dalla
piattaforma, seguendo le istruzioni presenti nella sezione "Pagamenti".</span></p>

<h4><strong>35. Ho chiesto il pagamento ma non vedo ancora soldi sul mio conto</strong></h4>
 <p class="c6"><span class="c2">Le richieste di pagamento pervenute entro il giorno 5 del mese successivo
alla conclusione dell'incarico, saranno pagate a 90 gg entro il giorno 10.
Le richieste di pagamento che invece perverranno oltre il giorno 5 del
mese successivo alla conclusione dell'incarico, saranno pagate a 90 gg
fine mese.</span></p>

<h4><strong>36. Non ho la partita iva, posso usare Rankit?</strong></h4>
 <p class="c6"><span class="c2">Si, possiamo collaborare tramite ritenuta d'acconto.</span></p>

<h4><strong>37. I contenuti realizzati per le campagne Creative, devono essere approvati dal cliente?</strong></h4>
 <p class="c6"><span class="c2">Esatto, tutti i contenuti realizzati per le campagne Creative devono essere
caricati in piattaforma ed essere sottoposti all'approvazione da parte del
cliente, prima di essere pubblicati sui tuoi profili social.</span></p>

<h4><strong>38. Quanto tempo devo lasciare i contenuti pubblicati sul mio profilo?</strong></h4>
 <p class="c6"><span class="c2">Per quanto concerne le Instagram Stories, dovranno restare sul profilo per
tutta la durata organica. Per quanto riguarda le altre tipologie di
contenuto statiche (post, reel, tweet) dovranno rimanere sul profilo
almeno fino alla fine della campagna, previa disposizioni diverse presenti
segnalate nella preview delle campagne.</span></p>

</div>

{/*<div class="col-lg-6 SignIn Forms px-5">

    <h3 class="title my-5">
    <Trans>{t('signin_title', {interpolation: {escapeValue: false}})}</Trans>
    </h3>


    <div class="form-floating my-4">
        <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com" value={signinEmail} onChange={e => setSigninEmail(e.target.value)} />
        <label for="floatingInput"><Trans>{t('signin_email', {interpolation: {escapeValue: false}})}</Trans></label>
      </div>

      <div class="form-floating my-3">
        <input type="password" class="form-control" id="floatingPassword" placeholder="Password" value={signinPassword} onChange={e => setSigninPassword(e.target.value)} />
        <label for="floatingPassword"><Trans>{t('signin_password', {interpolation: {escapeValue: false}})}</Trans></label>
      </div>

      {/*<div class="form-check">
        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
        <label class="form-check-label" for="flexCheckDefault">
        <Trans>{t('signin_remember_me', {interpolation: {escapeValue: false}})}</Trans>
        </label>
  </div>
      
      <button class="btn my-4" onClick={() => {signin()}}><Trans>{t('signin_button', {interpolation: {escapeValue: false}})}</Trans></button>


      <div>
        <a href="/signup" class="my-2 link"><Trans>{t('signin_signup', {interpolation: {escapeValue: false}})}</Trans></a>
      </div>

      <div class="my-4 ">
        <a href="/forgotpassword" class="my-4 link"><Trans>{t('signin_forgot', {interpolation: {escapeValue: false}})}</Trans></a>
      </div>

</div>*/}
</div>

</div>

    </section>
    <ToastContainer
        position="top-center"
        autoClose={4000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        />
    </div>
    
  )
};

export default EnPrivacyPolicy;