import { Route, Routes } from 'react-router-dom';
import Home from "./pages/Home";
import "./App.css"
import Signin from './pages/Signin';
import Signup from './pages/Signup';
import Signupguest from './pages/Signupguest';
import Forgotpassword from './pages/Forgotpassword';
import ItPrivacyPolicy from './pages/ItPrivacyPolicy';
import EnPrivacyPolicy from './pages/EnPrivacyPolicy';
import ItCookiePolicy from './pages/ItCookiePolicy';
import EnCookiePolicy from './pages/EnCookiePolicy';
import ItTermsConditions from './pages/ItTermsConditions';
import EnTermsConditions from './pages/EnTermsConditions';
import Recoveraccount from './pages/Recoveraccount';
import EnFaq from './pages/EnFaq';
import ItFaq from './pages/ItFaq';
//import { AdvertiserSignUp } from './pages/AdvertiserSignUp';


function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/signin" element={<Signin/>}></Route>
        <Route path='/signup/guest' element={<Signupguest />} ></Route>
        <Route path='/signup/driver' element={<Signup />} ></Route>
        <Route path='/forgotpassword' element={<Forgotpassword />}></Route>
        <Route path='/recoveraccount/:code/:type' element={<Recoveraccount />}></Route>
        <Route path="/privacy-policy-it" element={<ItPrivacyPolicy/>}></Route>
        <Route path="/privacy-policy-en" element={<EnPrivacyPolicy/>}></Route>
        <Route path="/cookie-policy-it" element={<ItCookiePolicy/>}></Route>
        <Route path="/cookie-policy-en" element={<EnCookiePolicy/>}></Route>
        <Route path="/termini-condizioni-it" element={<ItTermsConditions/>}></Route>
        <Route path="/terms-conditions-en" element={<EnTermsConditions/>}></Route>
        <Route path="/faq-it" element={<ItFaq/>}></Route>
        <Route path="/faq-en" element={<EnFaq/>}></Route>
      </Routes>
    </div>
  );
}

export default App;
