import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RankitLogo from "../Images/Rankit-Logo.png"
import User from "../Images/user.png"
import Campaign from "../Images/Campaign.png"
import PersonPlus from "../Images/PersonPlus.png"
import Socialize from "../Images/Socialize.png"
import Review from "../Images/review.png"
import Driver1 from "../Images/driver1.png"
import Guest1 from "../Images/guest1.png"
import Picture from "../Images/Picture.png"
import LinkedIn from "../Images/LinkedIn.png"
import Add from "../Images/Add.png"
import Instagram from "../Images/Instagram.png"
import Facebook from "../Images/Facebook.png"
import Tiktok from "../Images/Tiktok.png"
import Twitter from "../Images/Twitter.png"
import Twitch from "../Images/Twitch.png"
import Line from "../Images/Line.png"
import Julian from "../Images/Julian-Opuni-MD-Fidelity-Bank-Ghana.webp" 
import Navbar from '../components/Navbar'
import { Trans, useTranslation } from 'react-i18next';
import {config_data} from "../config.js";

const Signupguest = () => {
  const { t } = useTranslation();
  //let { type } = useParams();
  let type='guest';
  const inputFile = useRef(null);
  // user
  const [signupType, setSignupType] = useState('guest');
  const [signupStep, setSignupStep] = useState(0);
  const [signupEmail, setSignupEmail] = useState(undefined);
  const [signupUsername, setSignupUsername] = useState(undefined);
  const [signupPassword, setSignupPassword] = useState(undefined);
  const [signupConfirmPassword, setSignupConfirmPassword] = useState(undefined);
  const [signupCountry, setSignupCountry] = useState(undefined);
  const [signupLanguage, setSignupLanguage] = useState(undefined);
  const [signupTerms, setSignupTerms] = useState(false);
  const [signupPrivacy, setSignupPrivacy] = useState(false);
  // personal info
  const [signupFirstname, setSignupFirstname] = useState(undefined);
  const [signupLastname, setSignupLastname] = useState(undefined);
  const [signupDateofbirth, setSignupDateofbirth] = useState(undefined);
  const [signupPhone, setSignupPhone] = useState(undefined);
  const [signupCity, setSignupCity] = useState(undefined);
  const [signupProfession, setSignupProfession] = useState(undefined);
  const [signupPicture, setSignupPicture] = useState(`/uploads/62bf62db288e9002d90afbaaea198a0efb982e3cfa76d15a26d2999d4baec233.png`);
	const [isFilePicked, setIsFilePicked] = useState(false);
  // social media assets
  /*const [signupSocialMediaAssets, setSignupSocialMediaAssets] = useState([]);
  const [signupSocialMediaAssetsUrls, setSignupSocialMediaAssetsUrls] = useState([]);
  const [signupSocialMediaAssetsLinkedinName, setSignupSocialMediaAssetsLinkedinName] = useState(undefined);
  const [signupSocialMediaAssetsLinkedinUrl, setSignupSocialMediaAssetsLinkedinUrl] = useState(undefined);
  const [signupSocialMediaAssetsInstagramName, setSignupSocialMediaAssetsInstagramName] = useState(undefined);
  const [signupSocialMediaAssetsInstagramUrl, setSignupSocialMediaAssetsInstagramUrl] = useState(undefined);
  const [signupSocialMediaAssetsFacebookName, setSignupSocialMediaAssetsFacebookName] = useState(undefined);
  const [signupSocialMediaAssetsFacebookUrl, setSignupSocialMediaAssetsFacebookUrl] = useState(undefined);
  const [signupSocialMediaAssetsTikTokName, setSignupSocialMediaAssetsTikTokName] = useState(undefined);
  const [signupSocialMediaAssetsTikTokUrl, setSignupSocialMediaAssetsTikTokUrl] = useState(undefined);
  const [signupSocialMediaAssetsTwitterName, setSignupSocialMediaAssetsTwitterName] = useState(undefined);
  const [signupSocialMediaAssetsTwitterUrl, setSignupSocialMediaAssetsTwitterUrl] = useState(undefined);
  const [signupSocialMediaAssetsTwitchName, setSignupSocialMediaAssetsTwitchName] = useState(undefined);
  const [signupSocialMediaAssetsTwitchUrl, setSignupSocialMediaAssetsTwitchUrl] = useState(undefined);*/




  
  const onButtonClick = () => {
    // `current` points to the mounted file input element
   inputFile.current.click();
  };

  const changeHandler = async (event) => {
    console.log('HEREEEEE');
		setIsFilePicked(true);
    console.log(event.target.files[0]);
    var formData = new FormData();
    //var imagefile = document.querySelector('#file');
    formData.append('file', event.target.files[0]);
    const response = await axios.post(`${config_data['node_url']}/upload`, formData);
    //console.log(response['data']['data']);
    setSignupPicture(response['data']['data']);
	};

  // review and start //


  const manage = async (step) => {
    try {
      console.log(`EMAIL ${signupEmail}`);
      console.log(`TERMS ${signupTerms}`);
      console.log(`PRIVACY ${signupPrivacy}`);
      console.log(`STEP ${signupStep}`);
      var filter = /^([a-zA-Z0-9_+\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/; 
      var filter_strength = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/;



      if(signupStep==0 &&  (signupEmail==undefined || !filter.test(signupEmail))) {
        toast.error(t('signup_errors_email'));
      } else if(signupStep==0 && signupUsername==undefined) {
        toast.error(t('signup_errors_username'));
      } else if(signupStep==0 && (signupUsername!=undefined && signupUsername.length<5)) {
        toast.error(t('signup_errors_username_length'));
      } else if(signupStep==0 && signupPassword==undefined) {
        toast.error(t('signup_errors_password'));
      } else if(!filter_strength.test(signupPassword)) {
        toast.error(t('signup_errors_password_strength'));
      } else if(signupStep==0 && signupConfirmPassword==undefined) {
        toast.error(t('signup_errors_confirm_password'));
      } else if(signupStep==0 && signupPassword!=signupConfirmPassword) {
        toast.error(t('signup_errors_wrong_passwords'));
      } else if(signupStep==0 && (signupCountry==undefined || signupCountry==0)) {
        toast.error(t('signup_errors_country'));
      } else if(signupStep==0 && (signupLanguage==undefined || signupLanguage==0)) {
        toast.error(t('signup_errors_language'));
      } else if(signupStep==0 && signupTerms==false) {
        toast.error(t('signup_errors_terms'));
      } else if(signupStep==0 && signupPrivacy==false) {
        toast.error(t('signup_errors_privacy'));
      } else if(signupStep==1 && signupFirstname==undefined) {
        toast.error(t('signup_errors_firstname'));
      } else if(signupStep==1 && signupLastname==undefined) {
        toast.error(t('signup_errors_lastname'));
      } else if(signupStep==1 && signupDateofbirth==undefined) {
        toast.error(t('signup_errors_dateofbirth'));
      } else if(signupStep==1 && ((moment().unix() - moment(signupDateofbirth).unix()) < 567648000)) {
        toast.error(t('signup_errors_dateofbirth'));
      } else if(signupStep==1 && signupPhone==undefined) {
        toast.error(t('signup_errors_phone'));
      } else if(signupStep==1 && signupCity==undefined) {
        toast.error(t('signup_errors_city'));  
      } else if(signupStep==1 && signupProfession==undefined) {
        toast.error(t('signup_errors_profession'));  
      } else if(signupStep==1 && (signupPicture==undefined || signupPicture!=undefined && isFilePicked==false)) {
        toast.error(t('signup_errors_picture'));
      //} else if(signupStep==2 && signupSocialMediaAssets.length==0) {
        //toast.error(t('signup_errors_social_media_assets_count')); 
      } else {
        console.log(moment().unix());
        console.log(moment(signupDateofbirth).unix());
        if(signupStep==0) {
          console.log(`CHECK USERNAME AND EMAIL`);
          var response = await axios.post(`${config_data['node_url']}/check_email`, { email: signupEmail });
          console.log(response)
          if(response['data']['data']=='0') {
            toast.error(t('signup_errors_email_already'));
            return false;
          }
          response = await axios.post(`${config_data['node_url']}/check_username`, { username: signupUsername });
          if(response['data']['data']=='0') {
            toast.error(t('signup_errors_username_already'));
            return false;
          }
          
        }
        //return false;
        if(step<2) {
          // ADVANCE
          var tabs = document.querySelectorAll('[att="navatt"]');
          var tabscontent = document.querySelectorAll('[role="tabpanel"]');
          console.log(tabscontent);
          for(var i=0; i<tabs.length; i++) {
            console.log(tabs[i]);
            //console.log(tabs[i].type);
            tabs[i].setAttribute("class", "nav-link");
            tabs[i].setAttribute("aria-selected", "false");
          }
          for(var i=0; i<tabscontent.length; i++) {
            tabscontent[i].setAttribute("class", "tab-pane fade");
          }
          tabs[step+1].setAttribute("class", "nav-link active");
          tabs[step+1].setAttribute("aria-selected", "true");
          tabscontent[step+1].setAttribute("class", "tab-pane fade show active");
          setSignupStep(step+1);
          if(step==2) {
            for(var i=0; i<tabs.length; i++) {
              tabs[i].setAttribute("data-bs-toggle", "pill");
            }
          }
        } else {
          const response = await axios.post(`${config_data['node_url']}/signup`, {email: signupEmail, username: signupUsername, password: signupPassword, country: signupCountry, language: signupLanguage, firstname: signupFirstname, lastname: signupLastname, birth_date: moment(signupDateofbirth).format('DD/MM/YYYY'), phone: signupPhone, city: signupCity, profession: signupProfession, image: signupPicture, user_type: signupType});
          if(response['data']['result']!='ok') {
            toast.error(response['data']['data']);
            return false;
          } else {
            toast.success(t('signup_ok')); //, {onClose: () => window.location.reload()}
          }  
        }
      }
    } catch(err) {
      toast.error(err.toString());
    }
  }

  return (
    
    <div class="dark">
    
    <Navbar/>
    {type && (type=='guest') && (
      <section class="signUp my-5 py-5">
        <div class="container my-3">
          <div class="row align-items-center ">
            <ul
              class="nav nav-pills row justify-content-center mb-3 px-3 px-lg-0"
              id="pills-tab"
              role="tablist"
            >
              {/*<li class="nav-item col-lg-2" role="presentation">
                <button
                  class="nav-link active"
                  id="pills-path-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-path"
                  type="button"
                  role="tab"
                  aria-controls="pills-path"
                  aria-selected="true"
                >
                  <img
                    src={Campaign}
                    alt=""
                    class="img-fluid mx-2"
                  />{" "}
                  <Trans>{t('signup_select_path_title', {interpolation: {escapeValue: false}})}</Trans>
                </button>
              </li>*/}


{/*data-bs-toggle="pill"*/}
              <li class="nav-item col-lg-2" role="presentation">
                <button
                  class="nav-link active"
                  id="pills-user-tab"
                  
                  data-bs-target="#pills-user"
                  att="navatt"
                  type="button"
                  role="tab"
                  aria-controls="pills-user"
                  aria-selected="true"
                >
                  <img
                    src={User}
                    alt=""
                    class="img-fluid mx-2"
                  />{" "}
                  <Trans>{t('signup_user_title', {interpolation: {escapeValue: false}})}</Trans>
                </button>
              </li>

              <li class="nav-item col-lg-2" role="presentation">
                <button
                  class="nav-link"
                  id="pills-Personal-tab"
          
                  att="navatt"
                  data-bs-target="#pills-Personal"
                  type="button"
                  role="tab"
                  aria-controls="pills-Personal"
                  aria-selected="false"
                  onClick={(e) => { e.stopPropagation();}}
                >
                  <img
                    src={PersonPlus}
                    alt=""
                    class="img-fluid mx-2"
                  />{" "}
                 <Trans>{t('signup_personal_info_title', {interpolation: {escapeValue: false}})}</Trans>
                </button>
              </li>



              <li class="nav-item col-lg-3" role="presentation">
                <button
                  class="nav-link"
                  id="pills-Review-tab"
      
                  data-bs-target="#pills-Review"
                  att="navatt"
                  type="button"
                  role="tab"
                  aria-controls="pills-Review"
                  aria-selected="false"
                >
                  <img
                    src={Review}
                    alt=""
                    class="img-fluid mx-2"
                  />{" "}
                  <Trans>{t('signup_review_title', {interpolation: {escapeValue: false}})}</Trans>
                </button>
              </li>
            </ul>
            <div class="tab-content" id="pills-tabContent">
              {/* Path Selection */}
            {/*}  <div
                class="tab-pane fade show active"
                id="pills-path"
                role="tabpanel"
                aria-labelledby="pills-path-tab"
              >
                <div class="container">
                  <div class="row align-items-center my-5 g-5">
                    <div class="col-lg-6 Driver px-4">
                      <img
                        src={Driver1}
                        alt=""
                        class="img-fluid my-4"
                      />

                      <h4 class="my-3">
                      <Trans>{t('signup_select_path_become_driver', {interpolation: {escapeValue: false}})}</Trans>
                      </h4>

                      <a
                        href="#"
                        class="btn btn-white my-5"
                      >
                        <Trans>{t('join_our_family_driver_button', {interpolation: {escapeValue: false}})}</Trans>
                      </a>
                    </div>

                    <div class="col-lg-6 Advertiser px-4 text-end">
                      <img
                        src={Guest1}
                        alt=""
                        class="img-fluid my-4"
                      />

                      <h4 class="my-3">
                      <Trans>{t('signup_select_path_become_advertiser', {interpolation: {escapeValue: false}})}</Trans>
                      </h4>

                      <a
                        href="#"
                        class="btn btn-white my-5"
                      >
                        <Trans>{t('join_our_family_advertiser_button', {interpolation: {escapeValue: false}})}</Trans>
                      </a>
                    </div>
                  </div>
                </div>
</div> */}

              {/* Path Selection */}

              {/* User */}

              <div
                class="tab-pane fade show active"
                id="pills-user"
                role="tabpanel"
                aria-labelledby="pills-user-tab"
              >
                <div class="container my-4 ">
                  <div class="row">
                    <div class="col-lg-6 Forms">
                      <div class="form-floating my-4 ">
                        <input
                          type="email"
                          class="form-control"
                          id="floatingInput"
                          placeholder="name@example.com"
                          onChange={e => setSignupEmail(e.target.value)}
                        />
                        <label for="floatingInput"><Trans>{t('signup_user_email', {interpolation: {escapeValue: false}})}</Trans></label>
                      </div>

                      <div class="form-floating my-4">
                        <input
                          type="text"
                          class="form-control"
                          id="floatingPassword"
                          placeholder="Password"
                          onChange={e => setSignupUsername(e.target.value)}
                        />
                        <label for="floatingPassword"><Trans>{t('signup_user_username', {interpolation: {escapeValue: false}})}</Trans></label>
                      </div>

                      <div class="form-floating my-4">
                        <input
                          type="password"
                          class="form-control"
                          id="floatingPassword"
                          placeholder="Password"
                          onChange={e => setSignupPassword(e.target.value)}
                        />
                        <label for="floatingPassword"><Trans>{t('signup_user_password', {interpolation: {escapeValue: false}})}</Trans></label>
                      </div>

                      <div class="form-floating my-4">
                        <input
                          type="password"
                          class="form-control"
                          id="floatingPassword"
                          placeholder="Password"
                          onChange={e => setSignupConfirmPassword(e.target.value)}
                        />
                        <label for="floatingPassword"><Trans>{t('signup_user_confirm_password', {interpolation: {escapeValue: false}})}</Trans></label>
                      </div>
                    </div>

                    <div class="col-lg-6 Forms px-lg-4">
                      <div class="form-floating my-lg-4 ">
                        {/*<input
                          type="email"
                          class="form-control"
                          id="floatingInput"
                          placeholder="name@example.com"
                          onChange={e => setSignupCountry(e.target.value)}
                        />*/}
                        <select class="form-control" style={{'color':'#b4b7bd', 'backgroundColor': "#161D31", 'borderColor': '#404656'}} onChange={e => setSignupCountry(e.target.value)}> 
                          <option value="0">{t('signup_user_country_select', {interpolation: {escapeValue: false}})}</option>
                        {config_data['countries'].map((c) => (
                          <option value={c['name']}>{c['name']}</option>
                          ))}
                        </select>
                        <label for="floatingInput"><Trans>{t('signup_user_country', {interpolation: {escapeValue: false}})}</Trans></label>
                      </div>

                      <div class="form-floating my-4">
                        {/*<input
                          type="text"
                          class="form-control"
                          id="floatingPassword"
                          placeholder="Password"
                          onChange={e => setSignupLanguage(e.target.value)}
                        />*/}
                        <select placeholder="Password" class="form-control" style={{'color':'#b4b7bd', 'backgroundColor': "#161D31", 'borderColor': '#404656'}} onChange={e => setSignupLanguage(e.target.value)}>
                        <option value="0">{t('signup_user_language_select', {interpolation: {escapeValue: false}})}</option>
                        {config_data['languages'].map((c) => (
                          <option value={c['name']}>{c['name']}</option>
                          ))}
                        </select>
                        <label for="floatingPassword"><Trans>{t('signup_user_language', {interpolation: {escapeValue: false}})}</Trans></label>
                      </div>

                      <div class="form-check my-3">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                          onClick={e => setSignupTerms(!signupTerms)}
                        />
                        <label class="form-check-label" for="flexCheckDefault">
                        <Trans><a href={t('signup_user_terms_url')} target="_blank">{t('signup_user_terms', {interpolation: {escapeValue: false}})}</a></Trans>
                        </label>
                      </div>

                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                          onClick={e => setSignupPrivacy(!signupPrivacy)}
                        />
                        <label class="form-check-label" for="flexCheckDefault">
                        <Trans><a href={t('signup_user_privacy_url')} target="_blank">{t('signup_user_privacy', {interpolation: {escapeValue: false}})}</a></Trans>
                        </label>
                      </div>

                      <div class="text-end">
                        <button class="btn my-4 " onClick={() => {manage(0)}}><Trans>{t('signup_user_button', {interpolation: {escapeValue: false}})}</Trans></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* User */}

              {/* Info */}
              <div
                class="tab-pane fade"
                id="pills-Personal"
                role="tabpanel"
                aria-labelledby="pills-Personal-tab"
              >
                <div class="container my-4 ">
                  <div class="row">
                    <div class="col-lg-6 Forms">
                    <div class="form-floating my-4">
                        <div
                          class="image"
                          style={{backgroundImage: `url(${config_data['node_url']}${signupPicture})`}}
                        >
                          <img
                            src={Picture}
                            alt=""
                            class="img-fluid Select"
                            onClick={onButtonClick}
                          />
                        </div>
                        <input type='file' id='file' ref={inputFile} onChange={changeHandler} style={{display: 'none'}}/> 
                      
                      </div>

                      <div class="form-floating my-4">                        
                      <input
                          type="text"
                          class="form-control"
                          id="floatingPassword"
                          placeholder="Password"
                          onChange={e => setSignupFirstname(e.target.value)}
                        />
                        <label for="floatingPassword"><Trans>{t('signup_personal_info_first_name', {interpolation: {escapeValue: false}})}</Trans></label>
                      </div>

                      <div class="form-floating my-4">
                        <input
                          type="text"
                          class="form-control"
                          id="floatingPassword"
                          placeholder="Password"
                          onChange={e => setSignupLastname(e.target.value)}
                        />
                        <label for="floatingPassword"><Trans>{t('signup_personal_info_last_name', {interpolation: {escapeValue: false}})}</Trans></label>
                      </div>

                      
                    </div>

                    <div class="col-lg-6 Forms px-lg-4">
                    {/*  <div class="form-floating my-lg-4 ">
                        <input
                          type="email"
                          class="form-control"
                          id="floatingInput"
                          placeholder="name@example.com"
                        />
                        <label for="floatingInput"><Trans>{t('signup_personal_info_country', {interpolation: {escapeValue: false}})}</Trans></label>
                        </div> */}

                      <div class="form-floating my-4">
                        <input
                          type="date"
                          class="form-control"
                          id="floatingPassword"
                          onChange={e => setSignupDateofbirth(e.target.value)}
                        />
                        <label for="floatingPassword"><Trans>{t('signup_personal_info_date_of_birth', {interpolation: {escapeValue: false}})}</Trans></label>
                      </div>

                      <div class="form-floating my-4">
                        <input
                          type="text"
                          class="form-control"
                          id="floatingPassword"
                          placeholder="Password"
                          onChange={e => setSignupPhone(e.target.value)}
                        />
                        <label for="floatingPassword"><Trans>{t('signup_personal_info_phone', {interpolation: {escapeValue: false}})}</Trans></label>
                      </div>

                      <div class="form-floating my-4">
                        <input
                          type="text"
                          class="form-control"
                          id="floatingPassword"
                          placeholder="Password"
                          onChange={e => setSignupCity(e.target.value)}
                        />
                        <label for="floatingPassword"><Trans>{t('signup_personal_info_city', {interpolation: {escapeValue: false}})}</Trans></label>
                      </div>

                      <div class="form-floating my-4">
                        <input
                          type="text"
                          class="form-control"
                          id="floatingPassword"
                          placeholder="Password"
                          onChange={e => setSignupProfession(e.target.value)}
                        />
                        <label for="floatingPassword"><Trans>{t('signup_personal_info_profession', {interpolation: {escapeValue: false}})}</Trans></label>
                      </div>

                      <div class="text-end">
                        <button class="btn my-4 " onClick={() => {manage(1)}}><Trans>{t('signup_personal_info_button', {interpolation: {escapeValue: false}})}</Trans></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Info */}

              
{/* Review */}
              <div
                class="tab-pane fade"
                id="pills-Review"
                role="tabpanel"
                aria-labelledby="pills-Review-tab"
              >
                <div class="container">
                  <div class="row  justify-content-center">
                    <div class="col-lg-5 reviewContainer my-4 p-4">
                      <div class="text-center my-5">
                        <div class=" my-4 d-flex justify-content-center text-center">
                          <div
                            class="picturecontainer "
                            style={{backgroundImage: `url(${config_data['node_url']}${signupPicture})`}}
                          ></div>
                        </div>

                        <p class="name">{signupFirstname} {signupLastname}</p>
                      </div>

                      <div class="miniTitle my-5">
                        {/*<h4 class="my-2"> Details</h4>*/}

                        <img
                          src={Line}
                          alt=""
                          class="img-fluid"
                        />

                        <p class="Bio my-4">
                          {" "}
                          <a href="" class="BioTitle">
                            {" "}
                            <Trans>{t('signup_review_username', {interpolation: {escapeValue: false}})}</Trans>:{" "}
                          </a>{" "}
                          {signupUsername}{" "}
                        </p>
                        <p class="Bio my-4">
                          {" "}
                          <a href="" class="BioTitle">
                            {" "}
                            <Trans>{t('signup_review_email', {interpolation: {escapeValue: false}})}</Trans>:{" "}
                          </a>{" "}
                          {signupEmail}{" "}
                        </p>
                        <p class="Bio my-4">
                          {" "}
                          <a href="" class="BioTitle">
                            {" "}
                            <Trans>{t('signup_review_phone', {interpolation: {escapeValue: false}})}</Trans>:{" "}
                          </a>{" "}
                          {signupPhone}{" "}
                        </p>
                        <p class="Bio my-4">
                          {" "}
                          <a href="" class="BioTitle">
                            {" "}
                            <Trans>{t('signup_review_dob', {interpolation: {escapeValue: false}})}</Trans>:{" "}
                          </a>{" "}
                          {moment(signupDateofbirth).format('DD/MM/YYYY')}
                        </p>
                        <p class="Bio my-4">
                          {" "}
                          <a href="" class="BioTitle">
                            {" "}
                            <Trans>{t('signup_review_language', {interpolation: {escapeValue: false}})}</Trans>:{" "}
                          </a>{" "}
                          {signupLanguage}{" "}
                        </p>
                        <p class="Bio mt-4">
                          {" "}
                          <a href="" class="BioTitle">
                            {" "}
                            <Trans>{t('signup_review_city', {interpolation: {escapeValue: false}})}</Trans>:{" "}
                          </a>{" "}
                          {signupCity}{" "}
                        </p>
                        <p class="Bio mt-4">
                          {" "}
                          <a href="" class="BioTitle">
                            {" "}
                            <Trans>{t('signup_review_country', {interpolation: {escapeValue: false}})}</Trans>:{" "}
                          </a>{" "}
                          {signupCountry}{" "}
                        </p>
                        <p class="Bio mt-4">
                          {" "}
                          <a href="" class="BioTitle">
                            {" "}
                            <Trans>{t('signup_review_profession', {interpolation: {escapeValue: false}})}</Trans>:{" "}
                          </a>{" "}
                          {signupProfession}{" "}
                        </p>
                      </div>
                    </div>

                  

                    <div class="text-end my-5">
                  {/*  <a href="#" class="btn" onClick={() => {manage(2)}}>
                        {" "}
                        <Trans>{t('signup_review_button', {interpolation: {escapeValue: false}})}</Trans>{" "}
                      </a> */}
                      <button class="btn my-4 " onClick={() => {manage(2)}}><Trans>{t('signup_review_button', {interpolation: {escapeValue: false}})}</Trans></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      )} 
      <ToastContainer
        position="top-center"
        autoClose={4000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        />
                        
        </div>
  
  );
}

export default Signupguest;