import React from 'react'
import Community from './Community'
import Content from './Content'
import Family from './Family'
import OurCompaigns from './OurCompaigns'
import OurProcess from './OurProcess'
import Partner from './Partner'
import Social from './Social'
import WhatWeDo from './what-we-do'


function About() {
  return (
    <>
    <WhatWeDo />
    <Family />
    <Content />
    <Social /> 
    <OurProcess />
    <OurCompaigns />
    <Community />
    <Partner />
    <Family />
</>
  )
}

export default About