import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BackgroundSvg1 from '../components/BackgroundImages/backgroundSvg1'
import Rankit from "../Images/Rankit-Logo.png"
import Navbar from '../components/Navbar';
import { Trans, useTranslation } from 'react-i18next';
import {config_data} from "../config.js";
import { useCookies } from 'react-cookie';

const EnTermsConditions = () => {
  const { t } = useTranslation();

  return (
<div class="dark">
<Navbar/>
<section class="signIn my-5 py-5">

<div class="container my-3">
<div class="row align-items-center">
    
<div class="col-lg-12 picture" style={{'color':'white'}}> 

<h3 class="title my-5" align="center">Terms and Conditions</h3>

<p>Rankit.it is owned by Mates4Digital S.r.l., VAT no. 02621700182, registered office Viale Liguria ,24 20143 Milano (MI).<br />
By registering and using Rankit services you declare that you have read and accept the following terms and conditions of service.<br />
Mates4Digital reserves the right to change the following terms and conditions at any time. <br />
Therefore, your continued use of the Rankit.co.uk software, even after changes to this agreement, constitutes your acceptance by implication.</p>

        <h4><strong>Registration and Account</strong></h4>
        <p>At the time of signing up, the information you enter must be accurate and truthful. The provision of the required data is indispensable for the use of the service. <br />
It is not permitted to use the personal data of other persons. <br />
The subscriber is solely responsible for his or her account activities and the security of his or her password. You may not use another person's account without permission. You must notify Rankit immediately if you suspect suspicious activity on your account. You may not publish or disclose your account access keys.
</p>     
        <h4><strong>Use and Reporting</strong></h4>
        <p>Mates4Digital grants registered Drivers and Advertisers non-exclusive and non-transferable use of the platform's marketplace service.<br />
No other license or use is granted, Mates4Digital holds all rights (including intellectual property and any other related rights) to any material, resources, or codes contained on the platform and its services.<br />
Drivers, bloggers (publishers) are not to be regarded as permanent collaborators or employees in any way.<br />
Drivers are responsible for the declaration of their remuneration and the payment of statutory taxes.
</p>

        <h4><strong>Restrictions</strong></h4>
        <p>Non è consentito rendere pubbliche, in modo diretto o indiretto, nessuna informazione confidenziale della piattaforma Rankit e dei suoi contenuti.
Non è consentito utilizzare nessuna informazione per creare servizi, software, documentazione di ogni genere, studi di mercato e ogni utilizzo assimilabile ai sopracitati.
Non è consentito riprodurre nessuna parte del codice di Rankit per nessun utilizzo.</p>

        <h3><strong>Driver (Publisher)</strong></h3>
        <h4><strong>Membership</strong></h4>
        <p>A Driver (publisher) shall mean any Rankit subscriber as a Driver, blogger, or social network account holder who wishes to join and publish in the form of content the communications of Mates4Digital clients who use the Rankit software (Advertiser). The registration and data required are mandatory to use the platform and are used exclusively for the management of the activity included in Rankit. <br />
The Driver undertakes to update his profile should the data entered change over time. 
</p>

        <h4><strong>Publications</strong></h4>
        <p>
        By joining campaigns, Drivers undertake to respect the content provided and/or approved by Rankit and not to alter the message during publication.<br />
Content published on social networks and blogs/sites/communities that deviates from the communication and/or approval provided by Rankit will be considered unlawful and will not be remunerated. Please note that content must comply with what is stated in the letters of engagement signed by the Driver, for all types of advertising campaigns that require the completion and signing of this agreement.<br />
They will also be deemed to be against policy:<br />

  <ul>
    <li>Any publication on social networks not indicated for each campaign</li>
<li>Any publication on websites/blogs/communities not indicated for each campaign and/or not indicated on the Driver profile</li>
<li>Any content manipulated in order to obtain more clicks that deviates from the communication indicated on Rankit</li>
<li>Any clicks generated by fraudulent automated systems</li>
<li>Any publication, or editing of content intended to harm the image of Mates4Digital clients using the Rankit software</li>
<li>Images, content, and messages that are offensive, against the law, detrimental to any brand or person, containing defamation, slander, material forbidden to minors under 18 years of age, or violent.</li>
</ul>
Mates4Digital reserves the right to exclude from the platform users who in any way do not operate according to the terms and conditions at its sole discretion.
        </p>

        <h4><strong>Materials</strong></h4>
        <p>
        The Driver undertakes to exclusively use the materials provided and/or approved by Mates4Digital made available on the Rankit software for the publication and/or creation of the required content. Any other material used without prior approval will compromise the remuneration of the same. The Driver is solely responsible for the use of unapproved and copyrighted material.
        </p>

        <h4><strong>Campaigns</strong></h4>
        <p>
        <b>Performance Campaigns</b> <br/>
Performance Campaigns, which are made available to Drivers in the Rankit software, have an indicated campaign period and a maximum number of participants, as well as a set daily/weekly/monthly budget. Once the maximum number of participants has been reached and/or the available budget indicated for the campaign has been used up and/or the indicated campaign period has expired, new Drivers may no longer join the campaign and the Drivers who have participated in the campaign shall stop earning from it.<br />
The Driver undertakes not to delete published and/or edited content for at least 30 days, under penalty of non-remuneration of the content, except for formats whose duration is naturally not less than 30 days by nature of the social media where the content is published.
 </p>

 <p>
 <b>Creative Campaigns </b> <br/>
 Within the Rankit platform, it is possible for Drivers to apply for Creative Campaigns. Drivers who apply will be screened by the Mates4Digital Creative team and selected subject to client approval. <br />
All Drivers who participate in Creative Campaigns will sign a Letter of Assignment with Mates4Digital in which they will find all the details for the collaboration. The selected Driver undertakes to comply with every point in the Letter of Assignment, failing which he/she will not be paid. 
 </p>

<p>Furthermore, Rankit assumes no liability for links and related third-party content placed on its website. Mates4Digital does not guarantee the quality of third-party systems and sites and is not responsible for the presence of malware or other harmful systems.</p>
        
        <h4><strong>Remuneration</strong></h4>
        <p>
        For each type of campaign, the remuneration for the activity in terms of clicks, publication of the material, and editing of content on their sites/blogs/communities are indicated. <br />
Each Driver may request payment on the first 5 days of the month following the end of the campaign in which they have joined and/or participated. <br />
Performance Campaigns and Creative Campaigns have different payment terms depending on the nature of the campaign.
</p>

<p>
<b>Performance Campaigns</b> <br/>
Payment of the fee is cumulative for all Performance Campaigns to which the Driver has subscribed and refers to the calendar month.
<br />
The payment terms for Drivers vary depending on the tax regime under which they operate ("invoice" with VAT under the flat-rate or ordinary regime or receipt with occasional services with withholding tax):
<ul>
<li>
In the case of a VAT-registered invoice, payment can be obtained within 90 days of receipt of the invoice (having reached the €50 threshold); in order to receive payment within 90 days, the Driver must send the invoice by the 5th day of the month following the month in which you reached the €50 threshold. Conversely, all invoices issued after the 5th day of the month will be paid within 90 days of receipt of the invoice.
</li>
<li>
In the case of occasional services with withholding tax, payment can be obtained within 90 days of receipt of the withholding tax (once the €50 threshold has been reached); to receive a payment within 90 days of the request, the Driver must send the invoice by the 5th day of the month following the relevant month. Conversely, all invoices issued after the 5th day of the month will be paid within 120 days of the request. Please note that, in this case, the remuneration accrued on Rankit shall be considered gross remuneration and therefore entered in this specific section of the withholding tax for the automatic calculation of the net remuneration.
</li>
</ul>
Accumulated credits are valid for the current calendar year, after which time they cannot be claimed and are reset.
</p>

<p>
<b>Creative Campaigns </b> <br/>
The Driver whether operating under an invoice tax regime with a flat-rate or ordinary regime or under an occasional service with withholding tax, must follow the instructions within the Letter of Assignment signed with Mates4Digital. 
As a general rule, payment for all services relating to Creative Campaigns takes place 90 days after receipt by the administration of Mates4Digital of the invoice or note relating to the occasional service, sent by the Driver at the end of the collaboration. 
<br />
Furthermore, the Driver undertakes to update the deadlines and payment details within 7 days of their change.

</p>


        <h4><strong>Promotional material</strong></h4>
        <p>
        Rankit reserves the right to use names, domains, communities, and social profiles of Drivers for promotion, training, marketing, and communications aimed at explaining Rankit's activities.
        </p>

        <h3><strong>Advertiser</strong></h3>
        <h4><strong>Membership</strong></h4>
        <p>
        Advertiser means any person registered with the Rankit software in the advertiser section and who intends to use the services of Mates4Digital through the Rankit software to disseminate content, materials, initiatives, and commission editorial content to promote a product, event, or advertising communication. <br />
Registration and the data required are mandatory to use the platform and are used exclusively for the management of the activity included in Rankit.<br />
The Advertiser undertakes to update his/her profile should the data entered change over time.

        </p>

        <h4><strong>Campaigns</strong></h4>
        <p>
        The Advertiser can use the platform once he has recharged his account. The Advertiser can insert its content. Measurement according to the Advertiser's monitoring systems is at the Advertiser's discretion. <br />
Mates4Digital undertakes to assist exclusively with the use of the platform in question.
<br />
Contents NOT permitted for dissemination:<br />
<ul><li>
Material prohibited to persons under 18 years of age;</li>
<li>
Material containing images or expressions that are violent, illegal, racist, or in any way offend the sensibilities of any minority;</li>
<li>Material for which you do not hold the copyright;</li>
<li>Links and content containing malware and systems harmful to users;</li>
<li>Content that misleads visitors;</li>
</ul>
Mates4Digital assumes no responsibility for links and related third-party content placed on its site.<br />
Mates4Digital does not guarantee the quality of third-party systems and sites and is not responsible for the presence of malware or other harmful systems.

        </p>

        <h4><strong>Payment</strong></h4>
        <p>
        The services offered by Mates4Digital via the Rankit software are available once you have recharged your account via PayPal and/or bank transfer and include campaigns and activities covered by that budget. The budget can be recharged several times.<br />
Mates4Digital undertakes to issue an invoice within 30 days of the end of each budget. The Advertiser undertakes to provide true and up-to-date invoicing data.

        </p>

        <h4><strong>Promotional material</strong></h4>
        <p>
        Mates4Digital reserves the right to use the names, domains, communities, and social profiles of Drivers for promotional, training, marketing, and communication purposes aimed at explaining the activities of Mates4Digital through the Rankit software.
        </p>

</div>

{/*<div class="col-lg-6 SignIn Forms px-5">

    <h3 class="title my-5">
    <Trans>{t('signin_title', {interpolation: {escapeValue: false}})}</Trans>
    </h3>


    <div class="form-floating my-4">
        <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com" value={signinEmail} onChange={e => setSigninEmail(e.target.value)} />
        <label for="floatingInput"><Trans>{t('signin_email', {interpolation: {escapeValue: false}})}</Trans></label>
      </div>

      <div class="form-floating my-3">
        <input type="password" class="form-control" id="floatingPassword" placeholder="Password" value={signinPassword} onChange={e => setSigninPassword(e.target.value)} />
        <label for="floatingPassword"><Trans>{t('signin_password', {interpolation: {escapeValue: false}})}</Trans></label>
      </div>

      {/*<div class="form-check">
        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
        <label class="form-check-label" for="flexCheckDefault">
        <Trans>{t('signin_remember_me', {interpolation: {escapeValue: false}})}</Trans>
        </label>
  </div>
      
      <button class="btn my-4" onClick={() => {signin()}}><Trans>{t('signin_button', {interpolation: {escapeValue: false}})}</Trans></button>


      <div>
        <a href="/signup" class="my-2 link"><Trans>{t('signin_signup', {interpolation: {escapeValue: false}})}</Trans></a>
      </div>

      <div class="my-4 ">
        <a href="/forgotpassword" class="my-4 link"><Trans>{t('signin_forgot', {interpolation: {escapeValue: false}})}</Trans></a>
      </div>

</div>*/}
</div>

</div>

    </section>
    <ToastContainer
        position="top-center"
        autoClose={4000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        />
    </div>
    
  )
};

export default EnTermsConditions;