import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import RankitLogo from "../Images/Rankit-Logo.png";
import Navbar from '../components/Navbar';
import { Trans, useTranslation } from 'react-i18next';
import {config_data} from "../config.js";
import { useCookies } from 'react-cookie';
import axios from 'axios';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export function Recoveraccount() {
  const { t } = useTranslation();
  let { code } = useParams();
  let { type } = useParams();
  const [signinEmail, setSigninEmail] = useState(undefined);
  const [signinPassword1, setSigninPassword1] = useState(undefined);
  const [signinPassword2, setSigninPassword2] = useState(undefined);

  const recover = async () => {
    try {
      var filter = /^([a-zA-Z0-9_+\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/; 
      var filter_strength = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/;
      if(signinEmail==undefined || !filter.test(signinEmail)) {
        toast.error(t('signin_errors_email'));
      } else if(signinPassword1==undefined || signinPassword2==undefined || signinPassword1!=signinPassword2) {
        toast.error(t('signin_errors_password'));
      } else if(!filter_strength.test(signinPassword1)) {
        toast.error(t('signup_errors_password_strength'));
      } else {
        const response = await axios.post(`${config_data['node_url']}/signup_recovery`, {email: signinEmail, password: signinPassword1, recovery_code: code, type: type});
        console.log(response.data.data);
        if(response.data.result=='ok') {
          toast.success(t('forgot_password_recovery_ok'), {onClose: () => window.location.href='/signin'});
        } else {
          toast.error(response.data.data);
          return false;
        }
      }
    } catch(err) {
      toast.error(err.toString());
    }
  }

  return (
    <div style={{ height: "93vh" }} class="dark">
<Navbar/>

      <section class="signIn my-5 py-5">
        <div class="container my-5">
          <h3 class="title my-5"><Trans t={t}>{t('recover_account_title', {interpolation: {escapeValue: false}})}</Trans></h3>

          <div class="row align-items-center justify-content-center my-5 py-5">
            <div class="col-lg-6 SignIn Forms px-5 ">
            <div class="form-floating my-4  ">
                <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com" value={signinEmail} onChange={e => setSigninEmail(e.target.value)} />
                <label for="floatingInput"><Trans>{t('signin_email', {interpolation: {escapeValue: false}})}</Trans></label>
              </div>
              <div class="form-floating my-4  ">
                <input type="password" class="form-control" id="floatingInput" placeholder="name@example.com" value={signinPassword1} onChange={e => setSigninPassword1(e.target.value)} />
                <label for="floatingInput"><Trans t={t}>{t('recover_account_password_1', {interpolation: {escapeValue: false}})}</Trans></label>
              </div>
              <div class="form-floating my-4  ">
                <input type="password" class="form-control" id="floatingInput" placeholder="name@example.com" value={signinPassword2} onChange={e => setSigninPassword2(e.target.value)} />
                <label for="floatingInput"><Trans t={t}>{t('recover_account_password_2', {interpolation: {escapeValue: false}})}</Trans></label>
              </div>

              <button class="btn my-4" onClick={() => {recover()}}><Trans t={t}>{t('recover_account_button', {interpolation: {escapeValue: false}})}</Trans></button>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer
        position="top-center"
        autoClose={4000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        />
    </div>
  );
}

export default Recoveraccount;