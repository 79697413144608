import React, { useState } from 'react'
import ButtonUp from "../../Images/To-top.png"


function ScrollButton() {
    const [visible, setVisible] = useState(false)
    const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300){
      setVisible(true)
    } 
    else if (scrolled <= 300){
      setVisible(false)
    }
  };
  const scrollToTop = () =>{
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
    });
  };
  window.addEventListener('scroll', toggleVisible);

  return (
        <div class="scroll-top" onClick={scrollToTop}>
            {visible===true ? <img class="img-fluid" src={ButtonUp} alt="Scroll-to-Top" />: null}
        </div>
  )
}

export default ScrollButton