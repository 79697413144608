import React from 'react'
import ProfileCard from "../../../Images/profile-card-img.png"
import SocialMediaCard from "../../../Images/Social-media-icon.png"
import { Trans, useTranslation } from 'react-i18next';

function WhatWeDo() {
    const { t } = useTranslation();
  return (
        <div class="section about" id="about">
        <div class="container-fluid px-lg-5 px-md-5">
            <div class="row">
                <div class="col-lg-6 pe-lg-5 ">
                    <h2><span class="text-primary">{t('what_we_do_1', {interpolation: {escapeValue: false}})}</span> {t('what_we_do_2', {interpolation: {escapeValue: false}})}</h2>
                    <p class="my-4"><Trans t={t}>{t('what_we_do_content', {interpolation: {escapeValue: false}})}</Trans> 
                    </p>
                    <div class="stats d-flex justify-content-between my-3">
                        <div class="span text-center">
                        <h4>700+</h4>
                        <p><Trans t={t}>{t('what_we_do_3', {interpolation: {escapeValue: false}})}</Trans></p> 
                        </div>
                        <div class="span text-center">
                            <h4>15+</h4>
                            <p><Trans t={t}>{t('what_we_do_4', {interpolation: {escapeValue: false}})}</Trans></p> 
                        </div>
                        <div class="span text-center">
                            <h4>40+</h4>
                            <p><Trans t={t}>{t('what_we_do_5', {interpolation: {escapeValue: false}})}</Trans></p> 
                        </div>
                    </div>
                </div>
                <div class="col-lg-2"></div>
                <div class="col-lg-4">
                    <div class="about-card py-5 text-dark bg-light shadow">
                        <div class="stats2 my-3">
                            <div class="d-flex justify-content-center">
                                <img class=" img-fluid" src={ProfileCard} alt="" />
                            </div>
                            <div class="d-flex justify-content-center pt-4">
                                <div class="span text-center">
                                    <h4>12</h4>
                                    <p>Posts</p> 
                                </div>
                                <div class="span text-center px-5">
                                    <h4>42k</h4>
                                    <p>Followers</p> 
                                </div>
                                <div class="span text-center">
                                    <h4>212</h4>
                                    <p>Following</p> 
                                </div>
                            </div>
                        </div>
                        <div class="social-icons d-flex justify-content-center">
                            <img class="img-fluid" src={SocialMediaCard} alt="facebook-icon" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default WhatWeDo