import React from 'react';
import Driver1 from "../../../Images/driver1.png";
import Guest1 from "../../../Images/guest1.png";
import { Trans, useTranslation } from 'react-i18next';


function Family() {
    const { t } = useTranslation();
  return (
    <div class="section family" id="family">
        <div class="container-fluid px-lg-5 px-md-5">
            <h2>{t('join_our_family_1', {interpolation: {escapeValue: false}})} <span class="text-primary">{t('join_our_family_2', {interpolation: {escapeValue: false}})}</span></h2>
            <div class="row py-3 ">
                <div class="col-lg-6">
                    <div class="driver py-5 mb-5">
                        <img class="img-fluid rounded-circle" src={Driver1} alt="driver-image" />
                        <h5 class="py-3">{t('join_our_family_driver_1', {interpolation: {escapeValue: false}})} <span class="text-primary">Rankit </span><Trans>{t('join_our_family_driver_3', {interpolation: {escapeValue: false}})}</Trans><Trans>{t('join_our_family_driver_2', {interpolation: {escapeValue: false}})}</Trans></h5>
                        <button class="btn"><a href="/signup/driver" style={{"text-decoration":"none"}}>{t('join_our_family_driver_button', {interpolation: {escapeValue: false}})}</a></button>
                    </div>
                </div>
                <div class="col-lg-6 mb-5">
                    <div class="guest py-5">
                        <img class="img-fluid rounded-circle" src={Guest1} alt="guest-image" />
                        <h5 class="py-3">{t('join_our_family_advertiser_1', {interpolation: {escapeValue: false}})} <span class="text-primary">Rankit </span><Trans>{t('join_our_family_advertiser_3', {interpolation: {escapeValue: false}})}</Trans><Trans>{t('join_our_family_advertiser_2', {interpolation: {escapeValue: false}})}</Trans></h5>
                        <button class="btn"><a href="/signup/advertiser" style={{"text-decoration":"none"}}>{t('join_our_family_advertiser_button', {interpolation: {escapeValue: false}})}</a></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Family