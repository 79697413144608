import React, { useState } from 'react';
import HeroImage from "../../Images/HERO.png";
import Rankit from "../../Images/Rankit-overlay.png";
import { Trans, useTranslation } from 'react-i18next';
import {config_data} from "../../config.js";

function Hero() {
    const { t } = useTranslation();
    const [isOn,setIsOn] = useState(false)
        let element = document.body;
        element.classList.toggle("dark-mode",isOn);
  return (
    <div class="hero" id="hero">
        <div class="container-fluid px-lg-5 px-md-5">
            <div class="row ">
                <div class="col-lg-6">
                    <h1 class="mt-5"><Trans t={t}>{t('hero_1', {interpolation: {escapeValue: false}})}</Trans><span class='border border-3 border-warning rounded-circle p-1'><Trans t={t}>{t('hero_2', {interpolation: {escapeValue: false}})}</Trans></span> <Trans t={t}>{t('hero_3', {interpolation: {escapeValue: false}})}</Trans></h1>
                    <a href="/signin" target="_blank"><button class="btn my-5">{t('hero_button')}</button></a>
                </div>
                <div class="col-lg-5 d-flex justify-content-center">
                    <img class="d-flex justify-content-center hero-img img-fluid" src={HeroImage} alt="Hero" />
                </div>
                <div class="col-lg-1">
                    <label class="switch switch-nav d-flex justify-content-end">
                        {isOn===false ? ( <input checked={isOn} type="checkbox"  onChange={(e)=>setIsOn(true)} />):( <input checked={!isOn} type="checkbox"  onChange={(e)=>setIsOn(false)} />)}
                        <input checked={isOn} type="checkbox"  onChange={(e)=>setIsOn(true)} />
                        <span class="slider round"></span>
                    </label>
                </div>
                <div class="d-flex justify-content-center overlay-img">
                    <img src={Rankit} alt="Rankit-overlay" />
                </div>
            </div>
        </div>
    </div>
  )
}

export default Hero