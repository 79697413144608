import React, { Fragment } from 'react'
import About from '../components/About'
import ContactUs from '../components/ContactUs'
import Footer from '../components/Footer'
import Hero from '../components/Hero'
import Navbar from '../components/Navbar'
import News from '../components/News'
import ScrollButton from '../components/ScrollButton'



const Home = () => {
  return (
    <>
    <Fragment>
    <Navbar />
    <Hero />
    <About />
    <News />
    <ContactUs />
    <Footer />
    <ScrollButton />
    </Fragment>
    </>
  )
}

export default Home; 