import React from "react";
import ellipse from "../../../Images/ellipse.png";
import Community1 from "../../../Images/community-img1.png";
import Community2 from "../../../Images/community-img2.png";
import Community3 from "../../../Images/community-img3.png";
import Community4 from "../../../Images/community-img4.png";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from 'react-owl-carousel';
import { Trans, useTranslation } from 'react-i18next';

function Community() {
    const { t } = useTranslation();
    const options = {
        loop:true,
    margin:150,
    responsiveClass:true,
    responsive:{
        0:{
            items:1,
            nav:true,
            margin:10,
        },
        600:{
            items:2,
            nav:true,
            margin:10,
        },
        1000:{
            items:3,
            nav:true,
        }
    }
    }
  
  return (
    <div class="section community" id=""community>
        <img class="img-fluid ellipse-img" src={ellipse} alt="ellipse-img" />
        <div class="container-fluid ps-lg-5 ps-md-5">
            <div class="row g-0">
                <div class="col-lg-6">
                    <h2 class="py-lg-3"><span class="text-primary"><Trans>{t('our_community_title_1', {interpolation: {escapeValue: false}})}</Trans></span> <Trans>{t('our_community_title_2', {interpolation: {escapeValue: false}})}</Trans></h2>
                    <p><Trans>{t('our_community_text', {interpolation: {escapeValue: false}})}</Trans></p>
                </div>
                <div class="col-lg-6 p-0 m-0">
                <OwlCarousel {...options} className='owl-carousel owl-theme py-3 py-lg-5'>
                        <div class="item">
                            <img class="img-fluid" src={Community1} alt="community-img1" />
                        </div>
                        {/*<div class="item">
                            <img class="img-fluid" src={Community2} alt="community-img2" />
  </div>*/}
                        <div class="item">
                            <img class="img-fluid" src={Community3} alt="community-img3" />
                        </div>
                        <div class="item">
                            <img class="img-fluid" src={Community4} alt="community-img4" />
                        </div>
                    </OwlCarousel>
                </div>
            </div>
        </div>
    </div>
  );
}

export default Community;
