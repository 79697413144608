import React from 'react'
import { Trans, useTranslation } from 'react-i18next';

function OurCompaigns() {
    const { t } = useTranslation();
  return (
    <div class="section campaigns" id="campaigns">
        <div class="container-fluid ps-lg-5 ps-md-5">
            <div class="row">
                <div class="col-lg-5 pt-lg-5 mt-lg-5 pb-4">
                    <h2 class="pb-3"><Trans>{t('our_campaigns_1_title_1', {interpolation: {escapeValue: false}})}</Trans> <span class="text-primary"><Trans>{t('our_campaigns_1_title_2', {interpolation: {escapeValue: false}})}</Trans></span></h2>
                    <p>
                    <Trans>{t('our_campaigns_1_text', {interpolation: {escapeValue: false}})}</Trans>
                    </p>
                    <h2 class="py-3 pt-lg-5"><span class="text-primary"><Trans>{t('our_campaigns_2_title_1', {interpolation: {escapeValue: false}})}</Trans></span> <Trans>{t('our_campaigns_2_title_2', {interpolation: {escapeValue: false}})}</Trans></h2>
                    <p>
                    <Trans>{t('our_campaigns_2_text', {interpolation: {escapeValue: false}})}</Trans>
                    </p>
                </div>
                <div class="col-lg-1"></div>
                <div class="col-lg-6 col-sm-0 campaign-bg">
                </div>
                <div class="d-flex justify-content-center mt-lg-5">
                <a href="/signin"><button class="btn my-lg-5 text-center"><Trans>{t('our_campaigns_button', {interpolation: {escapeValue: false}})}</Trans></button></a>
                </div>
            </div>
        </div>
    </div>
  )
}

export default OurCompaigns